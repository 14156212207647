import React, { Component } from 'react';
import { Helmet } from "react-helmet";

// import { apiHistory } from '../Api/ApiHistory';

class ComingSoon extends Component {

	componentDidMount() {
        // apiHistory.createHistory(window.location.pathname);
	}

	render() {
		return (
			<div>
				<div className="wrapper-container-main">
					<div className="container-main">
						<div className="wrapper-content">
							<div className="content">
								<h1 style={{
									margin: 'auto',
									paddingTop: '64px',
									textAlign: 'center'
								}}>Coming Soon</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

// const mapStateToProps = (state) => (
// 	{
// 	}
// );

export default ComingSoon;
// connect(mapStateToProps)(Contact);
