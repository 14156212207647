// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import Grid from "@material-ui/core/Grid";
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from "@material-ui/core/Snackbar";

import {Button, MenuItem} from '@material-ui/core';

import {Field, Form, Formik} from "formik";

import * as Yup from "yup";
import ErrorMessage from "../../components/100Include/ErrorMessage";
import moment from "moment";
import {MySnackbarContentWrapper} from "../../components/102MaterialDesign/MySnackbarContentWrapper";
import FormControl from "@material-ui/core/FormControl";
import DatePickerField from "../../components/103FormikCustom/DatePickerField";
import {DayOfWeek} from "../../constants/common";
import {getDayOfWeekSelectedDisplay} from "../../utils/Utility";
import {apiMessage} from "../../Api/_ApiMessage";
import {addMessage} from "../../Redux/Action/messageAction";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class SendPushMsgUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            repeatedDays: [],
            sendPushMsg: {
                send_date: '',
                repeated_time: '',
                selected_date: '',
                selected_time: '',
                title: '',
                content: '',
            },
            initForm: false,
            checkedAll: false
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        // Get detail Data
        const {t, i18n} = this.props;

        const params = {};
        apiMessage.getPushNotificationTemplatesDetail(this.props.id, params).then(obj => {
            if (obj && obj.status === 200) {
                if (obj.data) {
                    let sendPushMsg = obj.data;
                    sendPushMsg['selected_date'] = moment().format('YYYY-MM-DD');
                    sendPushMsg['selected_time'] = '';
                    sendPushMsg['repeated_time'] = '';
                    sendPushMsg['send_date'] = sendPushMsg.send_type;
                    if (sendPushMsg.send_type === "schedule") {
                        const fixScheduleSendDate = moment(sendPushMsg.fix_schedule_send_date);
                        sendPushMsg['selected_date'] = fixScheduleSendDate.format('YYYY-MM-DD');
                        sendPushMsg['selected_time'] = fixScheduleSendDate.format('HH:mm');
                    } else if (sendPushMsg.send_type === "repeat") {
                        sendPushMsg['repeated_time'] = sendPushMsg.repeat_send_hour;
                    }

                    let repeatedDays = [];
                    let checkAll = false;
                    if(sendPushMsg.repeat_send_week_day) {
                        repeatedDays = sendPushMsg.repeat_send_week_day.split(',');
                        repeatedDays.sort();
                        const allDaySelected = DayOfWeek.map(item => item.value);
                        checkAll = (repeatedDays.length === allDaySelected.length) && repeatedDays.every(function(element, index) {
                            return element === allDaySelected[index];
                        });
                        if(checkAll) {
                            repeatedDays.push('All')
                        }
                    }

                    const userItemBreadcrumb = [
                        {
                            title: t('ClientManagement:sendPushMsg.breadcrumb') + ' (' + 5 + ')',
                            link: '/' + i18n.language + '/push-msg-schedule'
                        },
                        {
                            title: `Edit [${sendPushMsg.title}]`,
                            link: null
                        }];
                    this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});

                    this.setState({
                        sendPushMsg: sendPushMsg,
                        repeatedDays: repeatedDays,
                        checkedAll: checkAll,
                        initForm: true
                    })
                }
            } else {
                this.setState({initForm: true})
            }
        })
    }

    _submitForm = (value) => {
        const {i18n} = this.props;
        let body = {
            "push_notification_template_id": this.props.id,
            "title": value.title,
            "content": value.content,
            "is_send_all": true
        }
        if (value.send_date === "schedule") {
            let selectedDateTime = moment(`${value.selected_date} ${value.selected_time}`, 'YYYY-MM-DD HH:mm')
            body['send_type'] = 'schedule';
            body['fix_schedule_send_date'] = selectedDateTime.valueOf();
        } else if (value.send_date === "repeat") {
            let repeatedDays = this.state.repeatedDays.length > 0 ? this.state.repeatedDays.filter(item => item !== "" && item !== "All") : [];
            body['send_type'] = 'repeat';
            body['repeat_send_week_day'] = repeatedDays.length > 0 ? repeatedDays.join(',') : '';
            body['repeat_send_hour'] = value.repeated_time;
        }

        apiMessage.updatePushNotificationTemplates(this.props.id, body).then(obj => {
            if (obj.status === 200) {
                const msgDsp = {
                    messageSnackbar: 'Update Send Push Message Template successfully',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                //Add msg
                this.props.addMessageP(msgDsp);
                this.props.history.push('/' + i18n.language + '/push-msg-schedule?previous=true')
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Update Send Push Message Template failed';
                //Add msg
                this.props.addMessageP({
                    messageSnackbar: error,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                });
            }
        })
    }

    _cancelButtonAction = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/push-msg-schedule?previous=true');
    }

    handleChange = event => {
        if(this.state.repeatedDays.includes('All')) {
            if(!event.target.value.includes('All')) {
                this.setState({
                    repeatedDays: [],
                    checkedAll: false,
                });
            } else {
                this.setState({
                    repeatedDays: event.target.value.filter(item => item !== "" && item !== "All"),
                    checkedAll: false,
                });
            }
        } else {
            if(event.target.value.includes('All')) {
                let selected = DayOfWeek.map(item => item.value);
                selected.push('All')
                this.setState({
                    repeatedDays: selected,
                    checkedAll: true,
                });
            } else {
                this.setState({
                    repeatedDays: event.target.value.filter(item => item !== "" && item !== "All"),
                    checkedAll: false,
                });
            }
        }
    };

    // FORM CONFIG
    formConfiguration = ({values, errors, touched, handleChange}) => {
        const {t} = this.props;
        return values && this.state.initForm && (
            <Form id="userForm" className="full-width" autoComplete="off">
                <Grid container xm={12} alignItems="center">
                    {this.state.MessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.MessageContent}
                        />
                    </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start">
                        <Grid item xs={2} className="form-item">
                            {t("ClientManagement:sendPushMsg.sendDate")}
                        </Grid>
                        <Grid item xs={10} className="form-row">
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start"
                                      className="mt10">
                                    <Grid item xs={"auto"}>
                                        <input
                                            type="radio"
                                            name="send_date"
                                            value="repeat"
                                            checked={values.send_date === "repeat"}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        {t("ClientManagement:sendPushMsg.repeated")}
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        <FormControl className='full-width'>
                                            <Select
                                                classes={{
                                                    root: 'custom-select select-multiple-checkbox'
                                                }}
                                                name="roles"
                                                multiple
                                                disableUnderline={true}
                                                value={this.state.repeatedDays}
                                                onChange={this.handleChange}
                                                input={<Input className="select-multiple-checkbox-input"/>}
                                                renderValue={selected => (`Every ${getDayOfWeekSelectedDisplay(selected)}`)}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem key="All" value="All">
                                                    <Checkbox checked={this.state.checkedAll}/>
                                                    <ListItemText primary={'All'}/>
                                                </MenuItem>
                                                {DayOfWeek.map(item => (
                                                    <MenuItem key={item.value} value={item.value}>
                                                        <Checkbox
                                                            checked={item && this.state.repeatedDays.indexOf(item.value) > -1}/>
                                                        <ListItemText primary={item.label}/>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        <input type="time" name="repeated_time" value={values.repeated_time}
                                               onChange={handleChange}/>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start"
                                      className="mt10">
                                    <Grid item xs={"auto"}>
                                        <input
                                            type="radio"
                                            name="send_date"
                                            value="schedule"
                                            checked={values.send_date === "schedule"}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        <DatePickerField name="selected_date" dateFormat="yyyy-MM-dd"/>
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        <input type="time" name="selected_time" value={values.selected_time}
                                               onChange={handleChange}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="mt20">
                        <Grid item xs={2} className="form-item">
                            {t("ClientManagement:sendPushMsg.msgTitle")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <input
                                    id="title"
                                    name="title"
                                    type="text"
                                    maxLength="40"
                                    onChange={handleChange}
                                    value={values.title}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <Grid item xs={9}>
                                        {errors.title && touched.title ? <ErrorMessage
                                            message={errors.title}/> : t("Common:Form.validator.required")}
                                    </Grid>
                                    <Grid item xs={3} className="align-right">
                                        {values.title ? values.title.length : 0} / 40 characters
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="mt20">
                        <Grid item xs={2} className="form-item">
                            {t("ClientManagement:sendPushMsg.msgContent")}
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="content" component="textarea" rows="5" maxLength="178"/>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="center" alignItems="flex-start">
                                    <Grid item xs={11} className="form-item">
                                        {errors.content && touched.content ? <ErrorMessage
                                            message={errors.content}/> : t("Common:Form.validator.required")}
                                    </Grid>
                                    <Grid item xs={1} className="form-item">
                                        {`${values.content ? values.content.length : 0} / 178 character`}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">Save</Button>
                        <Button type="button" className="second-button" onClick={() => {
                            this._cancelButtonAction()
                        }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const {sendPushMsg, openSnackbar, variantSnackbar, messageSnackbar} = this.state;
        const inputSendPushMsg = {...sendPushMsg};
        const Schema = Yup.object().shape({})

        return (
            sendPushMsg && (
                <div>
                    <div className="main__container flex-center-item">
                        <Formik
                            enableReinitialize
                            initialValues={inputSendPushMsg}
                            validationSchema={Schema}
                            onSubmit={this._submitForm}
                            component={this.formConfiguration}
                        />

                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={openSnackbar}
                            autoHideDuration={2000}
                            onClose={this.handleCloseSnackbar}
                        >
                            <MySnackbarContentWrapper
                                onClose={this.handleCloseSnackbar}
                                variant={variantSnackbar}
                                message={messageSnackbar}
                            />
                        </Snackbar>
                    </div>
                </div>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(SendPushMsgUpdate)));