// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
// Redux
import {connect} from 'react-redux';
// Material UI
import Grid from '@material-ui/core/Grid';
// Include
import { getValAfterSlash } from '../../utils/PaginationUtils';
import {getOrderData, getSelectedData} from "../../utils/02MaterialDesign/EnhancedTableUtil";
import EnhancedTable from "../../components/102MaterialDesign/EnhancedTable/EnhancedTable";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {setPaginator} from "../../Redux/Action/paginatorAction";
import { getPlatformKey } from '../../Api/_ApiFactoryWithHeader';
import { apiAuction } from '../../Api/_ApiAuction';

// Define column names
const rows = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Platform', link: '/report/:id' },
];

const rowsPerPageDefault = 15;
const orderByDefault = 'name';
const orderDefault = 'asc';

class ReportPlatformList extends Component {

    state = {
        // table settings
        order: orderDefault,
        orderBy: orderByDefault,
        selected: [],
        page: 0,
        rowsPerPage: rowsPerPageDefault,

        // component state
        data: [],
        search_type: '',
        search_keyword: '',
        amountOfData: '',
    };

    componentDidMount() {
        this._getDataDynamic(0, rowsPerPageDefault, orderByDefault, orderDefault, true);
    }

    prepareData = data => {
        const formattedData = (data && data.length > 0) ? (
            data.map(item => ({
                ...item,
                id: item.supplier_id,
                platform_key: getPlatformKey(item.supplier_id),
            }))
        ) : [];
        return [
            {
                name: 'Central',
                platform_key: 'central',
            },
            ...formattedData,
        ];
    };

    _getDataDynamic = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        const params = {
            '$orderby': `${orderBy} ${order}`,
            '$top': rowsPerPage,
            '$skip': skipAmount,
        };
        return apiAuction.getSuppliers(params)
            .then(res => {
                if (res && res.status === 200) {
                    const dataSum = getValAfterSlash(res.headers['content-range']);
                    this.setState({
                        amountOfData: dataSum,
                        search_keyword: searchSubject,
                        data: this.prepareData(res.data),
                    });
                    this.props.setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: 'Report Platform',
                                    link: null
                                }
                            ]
                        }
                    );
                } else {
                    const error = res.data && res.data.error ? res.data.error : 'Get Platforms failed';
                    throw new Error(error);
                }
            })
            .catch(err => {
                console.error('err', err);
            });
    }

    handleRequestSort = (event, property) => {
        const {page, rowsPerPage, search_keyword} = this.state;
        const orderData = getOrderData(property, this.state.orderBy, this.state.order);
        this.setState({order: orderData.order, orderBy: orderData.orderBy}, () => {
            this.settingPreviousPaginator(rowsPerPage, page, orderData.orderBy, orderData.order);
            this._getDataDynamic(page * rowsPerPage, rowsPerPage, orderData.orderBy, orderData.order, true, search_keyword);
        });
    };

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({selected: state.data.map(n => n.id)}));
            return;
        }
        this.setState({selected: []});
    };

    handleClick = (event, id) => {
        const {selected} = this.state;
        const newSelected = getSelectedData(id, selected);
        this.setState({selected: newSelected});
    };

    handleChangePage = (event, page) => {
        if (event) {
            const {rowsPerPage, order, orderBy} = this.state;
            this.setState({
                selected: [],
                page: page
            });
            this.settingPreviousPaginator(rowsPerPage, page, orderBy, order);
            this._getDataDynamic(page * rowsPerPage, rowsPerPage, orderBy, order, false);
        }
    };

    handleChangeRowsPerPage = event => {
        const {page, order, orderBy, search_keyword} = this.state;
        this.setState({rowsPerPage: parseInt(event.target.value)});
        this.settingPreviousPaginator(parseInt(event.target.value), page, orderBy, order);
        this._getDataDynamic(page * event.target.value, event.target.value, orderBy, order, false, search_keyword);
    };

    settingPreviousPaginator = (rowsPerPage, page, orderBy, order) => {
        this.props.setPaginator({
            page_id: 'report',
            rowsPerPage: rowsPerPage,
            page: page,
            orderBy: orderBy,
            order: order
        })
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1;

    render() {
        const {data, order, orderBy, selected, rowsPerPage, page, amountOfData} = this.state;
        return (
            <div className="report-page">
                <div className="main__container">
                    <Grid container alignItems="center">
                        {
                            data && <EnhancedTable
                                pageId={'report'}
                                primaryKey='platform_key'
                                displaySelectBox={false}
                                rows={rows}
                                data={data}
                                currentPage={page}
                                selected={selected}
                                order={order}
                                orderBy={orderBy}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                amountOfData={amountOfData}
                                isSelected={this.isSelected}
                                selectRow={this.handleClick}
                                handleSelectAllClick={this.handleSelectAllClick}
                                handleRequestSort={this.handleRequestSort}
                                handleChangePage={this.handleChangePage}
                                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        }
                    </Grid>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    setPaginator: data => dispatch(setPaginator(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportPlatformList)));