// Essential for all components
import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import { forEach, get, map } from 'lodash-es';
import Bluebird from 'bluebird';
import { Button, Select, MenuItem } from '@material-ui/core';
import { AgGridReact } from '@ag-grid-community/react';
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";
import {MySnackbarContentWrapper} from "../../components/102MaterialDesign/MySnackbarContentWrapper";
import Container from '@material-ui/core/Container';
import Snackbar from "@material-ui/core/Snackbar";
import { getDatasource, dateValueFormatter, booleanValueFormatter, defaultColumnDef } from '../../utils/AgGridUtils';

import {apiStore} from "../../Api/_ApiStore";

const ID_FIELD_NAME = 'store_id';

const columnDefCache = {};
function getColumnDefs(language) {
    let columnDefs = columnDefCache[language];
    if (!columnDefs) {
        const storeTypeLabelAttr = language && language.startsWith('zh') ? 'label_zh' : 'label_en';
        columnDefs = [{
            field: 'name',
            headerName: '收集點名稱',
            checkboxSelection: true,
            cellRenderer: function(params) {
                return <Link push to={'/' +
                    encodeURIComponent(language) +
                    '/store-management/' +
                    encodeURIComponent(get(params, ['data', ID_FIELD_NAME]))}
                >
                    {params.value}
                </Link>;
            }
        }, {
            field: 'store_type',
            headerName: '類型',
            valueFormatter: function(params) {
                return get(params, ['value', storeTypeLabelAttr], '');
            }
        }, {
            field: 'permanent',
            headerName: '長期?',
            valueFormatter: booleanValueFormatter
        }, {
            field: 'active',
            headerName: '狀態',
            valueFormatter: function(params) {
                const value = get(params, 'value');
                if (value == null) {
                    return '';
                }
                return value ? 'Active' : 'Inactive';
            }
        }, {
            field: 'address',
            headerName: '地址'
        }, {
            field: 'lastmoddate',
            headerName: '最後更新',
            valueFormatter: dateValueFormatter
        }];
        columnDefCache[language] = columnDefs;
    }
    return columnDefs;
}

function getRowId(params) {
    return params.data[ID_FIELD_NAME];
}

class StoreManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowCount: 0,
            // dataSource: null,
            storeTypes: [],
            selectedStoreTypeId: ' ',
        };
        this.handleGridReady = this.handleGridReady.bind(this);
        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
        this.handleAddClicked = this.handleAddClicked.bind(this);
        this.handleEditClicked = this.handleEditClicked.bind(this);
        this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
        this.handleStoreTypeChanged = this.handleStoreTypeChanged.bind(this);

        this.state.dataSource = this.getDataSource();
    }

    handleGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    handleSelectionChanged(evt) {
        const selectedRowCount = evt.api.getSelectedNodes().length;
        this.setState({
            selectedRowCount
        });
    }

    handleAddClicked() {
        const { i18n } = this.props;
        this.props.history.push('/' + encodeURIComponent(i18n.language) + '/store');
    }

    handleEditClicked() {
        if (!this.gridApi) {
            return;
        }
        const selectedRowNodes = this.gridApi.getSelectedNodes();
        if (!selectedRowNodes || selectedRowNodes.length !== 1) {
            return;
        }
        const id = selectedRowNodes[0].data[ID_FIELD_NAME];
        const { i18n } = this.props;
        this.props.history.push('/' + encodeURIComponent(i18n.language) + '/store/' + encodeURIComponent(id));
    }

    handleDeleteClicked() {
        if (!this.gridApi) {
            return;
        }
        const selectedRowNodes = this.gridApi.getSelectedNodes();
        if (!selectedRowNodes || !selectedRowNodes.length) {
            return;
        }
        return Bluebird.map(selectedRowNodes, (selectedRowNode) => {
            const id = get(selectedRowNode, ['data', ID_FIELD_NAME]);
            if (id != null) {
                return apiStore.deleteStore(id);
            }
        }, {
            concurrency: 4
        }).then(() => {
            this.setState({
                openSnackbar: true,
                messageSnackbar: 'Delete store(s) successfully',
                variantSnackbar: 'success'
            });
        }).catch(console.error);
    }

    handleStoreTypeChanged(evt) {
        const newValue = evt.target.value;
        this.setState({
            selectedStoreTypeId: newValue
        });
    }

    componentDidMount() {
        apiStore.getStoreTypeList().then(obj => {
            if(obj && obj.status === 200 && obj.data) {
                const storeTypes = obj.data;
                this.setState({
                    storeTypes
                })
            } else {
                console.log(obj);
            }
        }).catch(console.log);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedStoreTypeId, dataSource } = this.state;
        if (selectedStoreTypeId !== prevState.selectedStoreTypeId) {
            this.setState({
                dataSource: this.getDataSource()
            });
        }
        if (dataSource !== prevState.dataSource) {
            if (this.gridApi) {
                this.gridApi.setDatasource(dataSource);
            }
        }
    }

    getDataSource() {
        const { selectedStoreTypeId } = this.state;
        const baseQueryParams = {
            tenant: localStorage.getItem('asTenantId'),
            $expand: 'store_type'
        };
        if (selectedStoreTypeId !== ' ') {
            baseQueryParams.store_type = selectedStoreTypeId;
        }
        return getDatasource('admin/stores', baseQueryParams);
    }

    handleLogout() {
        this.props.logoutP();
    }

    handleCloseSnackbar() {
        this.setState({ openSnackbar: false });
    };

    render() {
        const {
            dataSource,
            selectedRowCount,
            storeTypes,
            selectedStoreTypeId,
            openSnackbar,
            variantSnackbar,
            messageSnackbar,
        } = this.state;
        return <Container>
            <h2>收集點</h2>
            <div className="data-grid-menu">
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="primary"
                    onClick={this.handleAddClicked}
                >
                    添加
                </Button>
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="primary"
                    onClick={this.handleEditClicked}
                    disabled={selectedRowCount !== 1}
                >
                    編輯
                </Button>
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="secondary"
                    onClick={this.handleDeleteClicked}
                    disabled={selectedRowCount === 0}
                >
                    刪除
                </Button>
                <Select
                    value={selectedStoreTypeId}
                    onChange={this.handleStoreTypeChanged}
                >
                    <MenuItem value=" ">
                        全部收集點
                    </MenuItem>
                    {map(storeTypes, storeType => {
                        return <MenuItem
                            value={storeType.store_type_id}
                        >
                            {storeType.label_zh}
                        </MenuItem>;
                    })}
                </Select>
            </div>
            <AgGridReact
                className="data-grid ag-theme-alpine"
                onGridReady={this.handleGridReady}
                pagination="true"
                rowSelection="multiple"
                onSelectionChanged={this.handleSelectionChanged}
                rowModelType="infinite"
                getRowId={getRowId}
                defaultColDef={defaultColumnDef}
                columnDefs={getColumnDefs(this.props.i18n.language)}
                datasource={dataSource}
            ></AgGridReact>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={this.handleCloseSnackbar}
            >
                <MySnackbarContentWrapper
                    onClose={this.handleCloseSnackbar}
                    variant={variantSnackbar}
                    message={messageSnackbar}
                />
            </Snackbar>
        </Container>;
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(StoreManagement)));