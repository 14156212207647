import axios from 'axios';
import qs from 'querystringify';
import { get, assign, clone, forIn, findKey } from 'lodash-es';
import { BASE_URL, WEB_CLIENT_CREDENTIAL_TOKEN } from '../Redux/Constant/ServerConstant';
import { refreshToken } from '../utils/AuthService';
import * as Config from "../config";

const urlModifier = url => (/:\/\//.test(url)) ? url : `${BASE_URL}/${url}`;

let accessToken = null;

const setAccessToken = (token) => {
  accessToken = token;
};

let forceLogout = null;
let forceLogoutMsg= '';

const setforceLogoutMsg = (msg) => {
  forceLogoutMsg=msg;
};

const setForceLogout = (callback) => {
  forceLogout = callback;
};

function getAuthorizationHeader() {
  return accessToken ? `Bearer ${accessToken}` : `Basic ${WEB_CLIENT_CREDENTIAL_TOKEN}`;
}

export const serverConfig = {
  central: {
    platformId: null,
    baseUrl: Config.API_CENTRAL_URL_BASE,
  },
};

const getBaseUrl = (server) => {
  return get(serverConfig[server], 'baseUrl');
};

export const getPlatformKey = (supplier_id) => {
  return findKey(serverConfig, ({ platformId }) => platformId === supplier_id);
};

function requestWithAuthHeaders(url, options, hasCustomContentType) {
  const optionsHeader = options.headers;
  const headers = {
    Accept: 'application/json',
    ...optionsHeader
  };
  if( !optionsHeader || !optionsHeader['Authorization']) {
    headers['Authorization'] = getAuthorizationHeader();
  }
  if (!hasCustomContentType && options.data != null && options.data !== '') {
    headers['Content-Type'] = 'application/json';
  }
  const requestConfig = { url: urlModifier(url), headers, ...options };
  const originalRequest = axios(requestConfig);
  if (!accessToken) {
    return originalRequest;
  }
  return originalRequest.catch(error => {
    // only do refresh token and retry if the request error is due to expired token
    if (!accessToken || !error || !error.response || error.response.status !== 401) {
      return error.response;
    }
    if ( error.response.data && error.response.data.error !== "The request requires user authentication" ) {
      return error.response;
    }

    return refreshToken().then(() => {
      let retryRequestConfig = clone(requestConfig);
      retryRequestConfig.headers = assign({},
        requestConfig && requestConfig.headers,
        {
          Authorization: getAuthorizationHeader()
        }
      );
      return axios(retryRequestConfig);
    }).catch(refreshOrSecondRequestError => {
      // reject the request with the original error since the caller usually
      // want to know the error related to the request they made.
      // TODO: find a way to add the refreshOrSecondRequestError in the error object.
      console.log(refreshOrSecondRequestError);
      if(error && error.response && error.response.status === 401 && forceLogout){
        console.log("force logout in APIFactory");
        if(forceLogoutMsg) alert(forceLogoutMsg);
        forceLogout();
      }
      throw error;
    })
  });
}

const addHeaders = (url, options) => requestWithAuthHeaders(url, options);

const xhrWithPayload = method => (url, payload, cancelToken, extra) => addHeaders(url, {
  cancelToken,
  method,
  data: JSON.stringify(payload),
  ...extra
}).then((response) => {
  return response;
});

const xhrWithoutPayload = method => (url, params, extraParams, cancelToken, extra) => {
  const hasQuestionMark = url.indexOf('?') !== -1;
  let resultUrl = url;
  const query = qs.stringify(params);
  if (query.length > 0) {
    resultUrl += (hasQuestionMark ? '&' : '?') + query;
  }
  if (extraParams != null && extraParams !== '') {
    resultUrl += extraParams;
  }
  return addHeaders(resultUrl, {
    cancelToken,
    method,
    ...extra
  })
    .then((response) => {
      let data;
      if (response && response.status !== 204) {
        data = response;
        //const totalItems = response.headers['Content-Length'];
        //data = totalItems ? { data: response.data, total_items: totalItems } : response.data;
        const range = response.headers['content-range'];
        if (range) {
          const rangeMatched = range.match(/\/(.*)/);
          const total = +rangeMatched[1];
          if (total || total === 0) {
            data.total = total;
          }
        }
      } else {
        data = response;
      }
      return data;
    });
};

export { setAccessToken, setForceLogout, setforceLogoutMsg, getBaseUrl };

export const api = {
  get: xhrWithoutPayload('GET'),
  delete: xhrWithoutPayload('DELETE'),
  post: xhrWithPayload('POST'),
  put: xhrWithPayload('PUT'),
  getWithCustomToken: (url, options) => {
    let headers = {
      Authorization: `Basic ${WEB_CLIENT_CREDENTIAL_TOKEN}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...options.headers
    };
    return addHeaders(url, {
      method: 'GET',
      headers
    });
  },
  putWithCustomToken: (url, data, options) => {
    let headers = {
      Authorization: `Basic ${WEB_CLIENT_CREDENTIAL_TOKEN}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...options.headers
    };
    return addHeaders(url, {
      method: 'PUT',
      headers,
      data: JSON.stringify(data)
    });
  },
  postUrlFormEncoded: (url, data, options) => {
    const formData = qs.stringify(data);
    return addHeaders(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*',
        ...options.headers
      },
      data: formData
    }).then(response => response);
  },
  postMultipart: (url, data, options) => {
    const formData = new FormData();
    forIn(data, (value, key) => {
      if (value !== null) {
        formData.append(key, value);
      }
    });
    return addHeaders(url, {
      method: 'POST',
      headers: {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        'Accept-Encoding': 'gzip',
        Authorization: getAuthorizationHeader(),
        ...options.headers
      },
      data: formData
    });
  },
  putMultipart: (url, data, options) => {
    const formData = new FormData();
    forIn(data, (value, key) => {
      if (value !== null) {
        formData.append(key, value);
      }
    });
    return addHeaders(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept-Encoding': 'gzip',
        ...options.headers
      },
      data: formData
    });
  },
  getXlsxFile: (url, options) => {
    let headers = {
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'text/plain'
    };
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }
    return requestWithAuthHeaders(url, {
      method: 'GET',
      headers,
      responseType: 'blob', // this must be set before ...options since options may include custom responseType for xls preview
      ...options,
    }, true);
  },
  getCSVFile: (url, options) => {
    let headers = {
      'Accept': 'text/csv',
      'Content-Type': 'text/csv'
    };
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }
    return requestWithAuthHeaders(url, {
      method: 'GET',
      headers,
      responseType: 'blob', // this must be set before ...options since options may include custom responseType for xls preview
      ...options,
    }, true);
  },
};
