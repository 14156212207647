import * as ActionTypes from '../Constant/ActionType';
// import { clearLoginData } from '../../Util/AuthService';

const initialState = {
	categories: []
};

const categoriesReducer = (state = initialState, action) => {

	switch (action.type) {

		case ActionTypes.GET_ALL_CATEGORIES: {
			return {
				...state,
				categories: action.data
			}
		}

		default:
			return state;
	}
};

export default categoriesReducer;
