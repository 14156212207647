import {api} from './_ApiFactoryWithHeader';

export const apiOrder = {
    getList: (params, cancelToken) => api.get('orders', params, null, cancelToken),

    getDetail: (id, params) => api.get(`orders/${encodeURIComponent(id)}`, params, null, null),

    create: (body) => {
        return api.post('orders', body);
    },

    update: (id, body) => {
        return api.put(`orders/${id}`, body);
    },

    delete: (ids) => {
        return api.delete(`orders?order_id%5Bin%5D=${encodeURIComponent(ids)}`, null, null, null);
    },

    getOrderItemSubTypeList: (params, cancelToken) => {
        const url = 'order_item_sub_types';
        return api.get(url, params, null, cancelToken);
    },
    getFactoryOrderItemSubTypeList: (params, cancelToken) => {
        const url = 'factory_order_item_sub_types';
        return api.get(url, params, null, cancelToken);
    },

    addOrderItem: (body, params) => {
        let url = 'order_items';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body);
    },
    updateOrderItem: (id, body) => {
        const url = 'order_items/' + id;
        return api.put(url, body);
    },


};
