// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import get from 'lodash-es/get';
import {connect} from "react-redux";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import Grid from "@material-ui/core/Grid";
import {Button, Checkbox, FormControlLabel} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {Field, Form, Formik} from "formik";
import * as Yup from "yup";

import ErrorMessage from "../../components/100Include/ErrorMessage";
import moment from "moment";
import DatePickerField from "../../components/103FormikCustom/DatePickerField";
import {apiAuction} from "../../Api/_ApiAuction";
import {addMessage} from "../../Redux/Action/messageAction";

class AuctionUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            source: '',
            suppliers: [],
            sourceValidateMsg: null,
            dateTimeValidate: null,
            isEdit: false,
            auction: {
                source: '',
                start_date: '',
                end_date: '',
                auction_slot_id: '',
                name: '',
                supplier: null,
                start_time: null,
                end_time: null,
                lastmoddate: null,
                selected_start_time: '',
                selected_end_time: '',
                auto_send_sealed_bid: false,
                auto_send_award: false,
            }
        };
    }

    componentDidMount() {
        this.getListSupplier();
        if(this.props.id) {
            this.setState({isEdit: true})
            this.getAuctionDetail();
        } else {
            const { t } = this.props;
            const breadcrumb = [{
                title: t("AuctionManagement:addAuction"),
                link: null
            }];
            this.props.setBreadcrumbP({breadcrumbData: breadcrumb});
        }
    }

    getListSupplier = () => {
        const params = {
            '$orderby': 'name asc',
        };
        apiAuction.getSuppliers(params).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data) {
                    const suppliers = obj.data;
                    this.setState({ suppliers });
                }
            } else {
                console.log(obj.error)
            }
        })
    }
    getAuctionDetail = () => {
        let params = {
            $expand: "supplier"
        }
        apiAuction.getAuctionDetail(this.props.id, params).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data && obj.data) {
                    const auctionDetail = obj.data;
                    this.setState( {
                        auction: this.prepareData(auctionDetail),
                        source: auctionDetail && auctionDetail.supplier ? auctionDetail.supplier.supplier_id : '',
                    });
                    const userItemBreadcrumb = [{
                        title: `Edit Auction [${auctionDetail.name}]`,
                        link: null
                    }];
                    this.props.setBreadcrumbP({breadcrumbData: userItemBreadcrumb});
                }
            } else {
                console.log(obj.error)
            }
        })
    }

    prepareData = data => {
        if(data) {
            const item = {...data};
            item.update_date = item.lastmoddate ? moment(item.lastmoddate).format('YYYY-MM-DD') : '';
            item.start_date = moment(item.start_time).format('YYYY-MM-DD');
            item.selected_start_time = moment(item.start_time).format('HH:mm');
            item.end_date = moment(item.end_time).format('YYYY-MM-DD');
            item.selected_end_time = moment(item.end_time).format('HH:mm');
            return item;
        }
        return null;
    };

    // BUTTON FUNCTION
    handleCloseSnackbar = () => {
        this.setState({ openSnackbar: false });
    };

    cancel = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/auction-management?previous=true');
    }

    eCb = (obj) => {
        console.log("eCb : ", obj);
    }

    validateDateTime = (startTime, endTime) => {
        if(startTime.valueOf() >= endTime.valueOf()) {
            this.setState({dateTimeValidate: 'Please input End Time greater than Start Date.'})
            return false;
        }
        return true;
    }

    validateSource = () => {
        this.setState({ sourceValidateMsg: this.state.source === '' ? 'Auction Source is required' : null });
    }

    _addAuctionAsync = (value) => {
        const { i18n } = this.props;
        let startTime = moment(`${value.start_date} ${value.selected_start_time}`, 'YYYY-MM-DD HH:mm')
        let endTime = moment(`${value.end_date} ${value.selected_end_time}`, 'YYYY-MM-DD HH:mm')
        this.validateSource();
        if(!this.validateDateTime(startTime, endTime) || this.state.source === '') return;

        let body = {
            "name": value.name,
            "supplier": this.state.source,
            "start_time": startTime.valueOf(),
            "end_time": endTime.valueOf(),
            "auto_send_sealed_bid": value.auto_send_sealed_bid,
            "auto_send_award": value.auto_send_award,
        }
        apiAuction.addAuction(body).then(obj => {
            if (obj.status === 201) {
                const msgDsp = {
                    messageSnackbar: 'Add new auction successfully',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                //Add msg
                this.props.addMessageP(msgDsp);
                this.props.history.push('/' + i18n.language + '/auction-management?previous=true')
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Add new auction failed';
                //Add msg
                this.props.addMessageP({
                    messageSnackbar: error,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                });
            }
        })
    }

    _updateAuctionAsync = (value) => {
        const { i18n } = this.props;
        let startTime = moment(`${value.start_date} ${value.selected_start_time}`, 'YYYY-MM-DD HH:mm')
        let endTime = moment(`${value.end_date} ${value.selected_end_time}`, 'YYYY-MM-DD HH:mm')
        this.validateSource();
        if(!this.validateDateTime(startTime, endTime) || this.state.source === '') return;
        let body = {
            "auction_slot_id": this.props.id,
            "name": value.name,
            "supplier": this.state.source,
            "start_time": startTime.valueOf(),
            "end_time": endTime.valueOf(),
            "auto_send_sealed_bid": value.auto_send_sealed_bid,
            "auto_send_award": value.auto_send_award,
        }

        apiAuction.updateAuction(this.props.id, body).then(obj => {
            if (obj.status === 200) {
                const msgDsp = {
                    messageSnackbar: 'Update auction successfully',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                //Add msg
                this.props.addMessageP(msgDsp);
                this.props.history.push('/' + i18n.language + '/auction-management?previous=true')
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : 'Update auction failed';
                //Add msg
                this.props.addMessageP({
                    messageSnackbar: error,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                });
            }
        })
    }

    _submitAuctionForm = (value) => {
        if(this.state.isEdit) {
            this._updateAuctionAsync(value);
        } else {
            this._addAuctionAsync(value);
        }
    }

    handleChange = event => {
        this.setState({ source: event.target.value, sourceValidateMsg: event.target.value === '' ? 'Auction Source is required' : null });
    };

    // FORM CONFIG
    formConfiguration = ({ values, errors, touched, handleChange, setFieldValue }) => {
        const { t, id } = this.props;
        const { suppliers, source } = this.state;
        const sourceValidate = this.state.sourceValidateMsg ? (
            <ErrorMessage message={this.state.sourceValidateMsg} />
        ) : t("Common:Form.validator.required");

        const dateTimeValidate = this.state.dateTimeValidate && <ErrorMessage message={this.state.dateTimeValidate} />;
        const selectedSupplier = suppliers.find(({ supplier_id }) => supplier_id === source);

        return values && (
            <Form id="auctionForm" className="full-width">
                <Grid container xm={12} alignItems="center">
                    {this.state.MessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.MessageContent}
                        />
                    </Grid>
                    }

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={3} className="form-item">
                            {t("AuctionManagement:auctionName")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <Field id="display_name" name="name" type="text" placeholder="" />
                            </Grid>
                            <Grid item xs={12}>
                                {errors.name && touched.name ? <ErrorMessage message={errors.name} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={3} className="form-item">
                            {t("AuctionManagement:source")}
                        </Grid>
                        <Grid item xs={9} className="form-item">
                            <Grid item xs={12}>
                                <FormControl variant="outlined" className='fifty' >
                                    <Select
                                        classes={{
                                            root: 'custom-select'
                                        }}
                                        value={this.state.source}
                                        onChange={e => {
                                            const newSupplier = suppliers.find(({ supplier_id }) => supplier_id === e.target.value);
                                            if (!(get(newSupplier, 'bid_type') || '').includes('sealed')) {
                                                setFieldValue('auto_send_sealed_bid', false);
                                                setFieldValue('auto_send_award', false);
                                            }
                                            return this.handleChange(e);
                                        }}
                                    >
                                        {suppliers.map(item => (
                                            <MenuItem key={item.supplier_id} value={item.supplier_id}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12}>
                                { sourceValidate }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={3} className="form-item">
                            {t("AuctionManagement:startTime")}
                        </Grid>
                        <Grid item xs={9} className="form-row">
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start"  className="mt10">
                                    <Grid item xs={"auto"}>
                                        <DatePickerField
                                            name="start_date"
                                            dateFormat="yyyy-MM-dd"
                                            maxDate={values.end_date ? new Date(values.end_date) : null}
                                        />
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        <input type="time" name="selected_start_time" value={values.selected_start_time} onChange={handleChange} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {errors.start_date && touched.start_date ? <ErrorMessage message={errors.start_date} /> : errors.selected_start_time && touched.selected_start_time ? <ErrorMessage message={errors.selected_start_time} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={3} className="form-item">
                            {t("AuctionManagement:endTime")}
                        </Grid>
                        <Grid item xs={9} className="form-row">
                            <Grid item xs={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start"  className="mt10">
                                    <Grid item xs={"auto"}>
                                        <DatePickerField
                                            name="end_date"
                                            dateFormat="yyyy-MM-dd"
                                            minDate={values.start_date ? new Date(values.start_date) : null}
                                        />
                                    </Grid>
                                    <Grid item xs={"auto"} className="ml20">
                                        <input type="time" name="selected_end_time" value={values.selected_end_time} onChange={handleChange} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                { dateTimeValidate }
                                {errors.end_date && touched.end_date ? <ErrorMessage message={errors.end_date} /> : errors.selected_end_time && touched.selected_end_time ? <ErrorMessage message={errors.selected_end_time} /> : t("Common:Form.validator.required")}
                            </Grid>
                        </Grid>
                    </Grid>

                    {(get(selectedSupplier, 'bid_type') || '').includes('sealed') && (
                        <>
                            <Grid container direction="row" justify="center" alignItems="flex-start" >
                                <Grid item xs={3} className="form-item">
                                    {t("AuctionManagement:autoSendBidInfo")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                    <FormControlLabel
                                        name="auto_send_sealed_bid"
                                        checked={values.auto_send_sealed_bid}
                                        onChange={handleChange}
                                        control={<Checkbox />}
                                        label=""
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="flex-start" >
                                <Grid item xs={3} className="form-item">
                                    {t("AuctionManagement:autoSendAwardInfo")}
                                </Grid>
                                <Grid item xs={9} className="form-item">
                                <FormControlLabel
                                    name="auto_send_award"
                                    checked={values.auto_send_award}
                                    onChange={handleChange}
                                    control={<Checkbox />}
                                    label=""
                                />
                                </Grid>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">{id ? 'Save' : 'Add'}</Button>
                        <Button type="button" className="second-button" onClick={() => { this.cancel() }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const { isEdit, auction } = this.state;
        const inputAuction = {...auction, isEdit: isEdit};
        const Schema = Yup.object().shape({
            name: Yup.string().required("Name is required"),
            start_date: Yup.string().required("Start Date is required"),
            selected_start_time: Yup.string().required("Start Time is required"),
            end_date: Yup.string().required("End Date is required"),
            selected_end_time: Yup.string().required("End Time is required"),
            auto_send_sealed_bid: Yup.boolean(),
            auto_send_award: Yup.boolean(),
        })

        return (
            auction && (
                <div>
                    <div className="main__container flex-center-item">
                        <Formik
                            enableReinitialize
                            initialValues={inputAuction}
                            validationSchema={Schema}
                            onSubmit={this._submitAuctionForm}
                            component={this.formConfiguration}
                        />
                    </div>
                </div>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(AuctionUpdate)));
