import { api } from "./_ApiFactoryWithHeader";


export const apiStore = {
    getStoreList: (params, cancelToken) => {
        const url = 'admin/stores';
        return api.get(url, params, null, cancelToken);
    },
    getStoreDetail: (id, params, cancelToken) => {
        const url = 'admin/stores/' + id;
        return api.get(url, params, null, cancelToken);
    },
    updateStore: (id, body) => {
        const url = 'admin/stores/' + id;
        return api.put(url, body);
    },
    addStore: (body, params) => {
        let url = 'admin/stores';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body);
    },
    deleteStore: (id) => {
        const url = 'admin/stores/' + id;
        return api.delete(url);
    },

    getStoreTypeList: (params, cancelToken) => {
        const url = 'store_types';
        return api.get(url, params, null, cancelToken);
    },
    getStoreTypeDetail: (id, params, cancelToken) => {
        const url = 'store_types/' + id;
        return api.get(url, params, null, cancelToken);
    },

    updateStoreTimeSlot: (id, body) => {
        const url = 'store_time_slots/' + id;
        return api.put(url, body);
    },
    addStoreTimeSlot: (body, params) => {
        let url = 'store_time_slots';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body);
    },
    deleteStoreTimeSlot: (id) => {
        const url = 'store_time_slots/' + id;
        return api.delete(url);
    },
};