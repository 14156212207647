// Essential for all components
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

import BaseListPage from "../../components/101BaseListPage/BaseListPage";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";
import moment from "moment";
import {
    getCurrentStateOfPage,
    getPreviousPage,
    getValAfterSlash,
    handleSpecialFieldName
} from "../../utils/PaginationUtils";
import queryString from 'query-string'
import { formatCurrency } from '../../utils/Utility';
import {COMMON_DATE_FORMAT} from "../../constants/common";
import {apiClient} from "../../Api/_ApiClient";
import {addMessage} from "../../Redux/Action/messageAction";

// Define column names
const rows = [
    {
        id: 'display_name',
        numeric: false,
        disablePadding: false,
        label: 'Client Name',
        link: '/client-management/:client_id',
        customUrlParam: ['client_id']
    },
    {id: 'wechat_id', numeric: true, disablePadding: false, label: 'WeChat'},
    {id: 'active_display', numeric: false, disablePadding: false, label: 'Active'},
    {id: 'credit', numeric: false, disablePadding: false, label: 'Credit Balance'},
    {id: 'max_credit', numeric: false, disablePadding: false, label: 'Max Credit'},
    {id: 'lastmoddate', numeric: false, disablePadding: false, label: 'Update Date', type: 'date'}
];

const rowsPerPageDefault = 10;
const orderByDefault = 'lastmoddate';
const orderDefault = 'desc';

class ClientManagement extends Component {

    state = {
        data: null,
        //pagination
        amountOfData: '',
        currentPage: 0,
        rowsPerPage: rowsPerPageDefault,
        orderBy: orderByDefault,
        order: orderDefault
    };


    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        let previousPage = values.previous ? getPreviousPage(this.props.currentPaginator, 'auction-management') : null;
        const {currentPage, rowsPerPage, orderBy, order} = getCurrentStateOfPage(previousPage, rowsPerPageDefault, orderByDefault, orderDefault)
        this.setState({
            currentPage: currentPage,
            rowsPerPage: rowsPerPage,
            orderBy: orderBy,
            order: order
        }, () => {
            this._getDataDynamic(currentPage * rowsPerPage, rowsPerPage, orderBy, order, true);
        })
    }

    _getDataDynamic = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        const {t, i18n} = this.props;
        orderBy = handleSpecialFieldName(orderBy);

        const params =
            {
                '$orderby': `${orderBy} ${order}`,
                '$top': rowsPerPage,
                '$skip': skipAmount,
                '$select': 'client_id,user,display_name,wechat_id,active,credit,max_credit,lastmoddate'
            };

        apiClient.getClients(params).then(rs => {
            if (rs && rs.status === 200) {
                if (isResetNavAndDataAmount) {
                    const dataSum = getValAfterSlash(rs.headers['content-range']);
                    this.setState({amountOfData: dataSum});
                    this.props.setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: t('ClientManagement:breadcrumb') + ' (' + dataSum + ')',
                                    link: '/' + i18n.language + '/client-management'
                                }
                            ]
                        }
                    );
                }
                this.setState({
                    data: this.prepareData(rs.data),
                });
            } else {
                console.log("eCb : ", rs);
            }
        });
    }

    prepareData = data => {
        return (data && data.length > 0) ? (
            data.map(item => ({
                ...item,
                id: item.client_id,
                active_display: item.active ? 'Yes' : 'No',
                update_date: moment(item.lastmoddate).format(COMMON_DATE_FORMAT),
                credit: formatCurrency(item.credit, ''),
                max_credit: formatCurrency(item.max_credit, ''),
            }))
        ) : [];
    };

    _createButtonAction = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/client');
    }

    _updateButtonAction = (id) => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/client/' + id);
    }

    _deleteButtonAction = (ids) => {
        if(ids && ids.length > 0) {
            const deletedUser = this.state.data.filter(item => ids.includes(item.client_id)) ;
            const {auth} = this.props;

            let isSelfDel = deletedUser.findIndex(item => item.user === auth.username);
            Promise.all(ids.map(id => apiClient.deleteClient(id, {}).then(rs => {
                if (rs.status === 204) {
                    return;
                } else {
                    throw new Error(rs);
                }
            })))
                .then(() => {
                    const msgDsp = {
                        messageSnackbar: 'Delete clients successfully',
                        variantSnackbar: 'success',
                        key: new Date().getTime(),
                    };
                    this.props.addMessageP(msgDsp);
                    this._getDataDynamic(0, this.state.rowsPerPage, this.state.orderBy, this.state.order, true, null);
                    if(isSelfDel >= 0){
                        setTimeout(this.handleLogout, 2000);
                    }
                })
                .catch(err => {
                    console.log("eCb : ", err);
                });
        }
    }

    _sendPushButtonAction = (id) => {
        console.log('_sendPushButtonAction')
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/send-push-msg/' + id);
    }

    _sendWeChatButtonAction = (id) => {
        console.log('_sendWeChatButtonAction')
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/send-wechat/' + id);
    }

    _sendOneToOneButton = (id) => {
        console.log('_sendOneToOneButton')
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/send-msg/' + id);
    }

    render() {
        const {data, amountOfData, currentPage, rowsPerPage, orderBy, order} = this.state;
        return (
            data &&
            <div>
                <BaseListPage
                    pageId={'client-management'}
                    mainClass={'client-mgt-page'}
                    primaryKey={'client_id'}
                    displaySearchBar={false}
                    displayActionBar={true}

                    createButton={true}
                    createButtonText={'New Client'}
                    createButtonAction={this._createButtonAction}

                    updateButton={true}
                    updateButtonText="Edit"
                    updateButtonAction={this._updateButtonAction}

                    deleteButton={true}
                    deleteButtonText="Delete"
                    deleteButtonAction={this._deleteButtonAction}

                    sendWeChatButton={true}
                    sendWeChatButtonText="Send WeChat to All"
                    sendWeChatButtonAction={this._sendWeChatButtonAction}

                    sendPushButton={true}
                    sendPushButtonText="Send Push to All"
                    sendPushButtonAction={this._sendPushButtonAction}

                    sendOneToOneButton={true}
                    sendOneToOneButtonText="Send One-2-One Message to All"
                    sendOneToOneButtonAction={this._sendOneToOneButton}

                    getDataDynamic={this._getDataDynamic}
                    currentPage={currentPage}
                    rows={rows}
                    data={data}
                    displaySelectBox={true}
                    orderDefault={order}
                    orderByDefault={orderBy}
                    rowsPerPageDefault={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20]}
                    amountOfData={amountOfData}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ClientManagement)));
