export const COMMON_DATE_FORMAT = 'YYYY-MM-DD';

export const IMAGE_UPLOAD_TYPE = [".jpg", ".jpeg", ".jpe", ".jif", ".jfif", ".jfi", ".png", ".gif", ".webp", ".tiff", ".tif", ".raw", ".arw", ".cr2", ".nrw", ".k25", ".bmp", ".dib", ".jp2", ".j2k", ".jpf", ".jpx", ".jpm", ".mj2", ".svg", ".svgz"];
export const VIDEO_UPLOAD_TYPE = ['.mp4', '.avi', '.flv', '.webm', '.m4v', '.mpeg'];

export const siteTypes = [
    {
        label: 'Content Management System',
        value: 'cms',
    },
    {
        label: 'Facility Management Platform',
        value: 'fmp',
    },
    {
        label: 'Data Analytics',
        value: 'da',
    },
];

export const Language = [
    {
        value: 'chinese',
        label: 'Chinese'
    },
    {
        value: 'english',
        label: 'English'
    }
];

export const ArticleStatus = [
    {
        value: 'online',
        label: 'Online'
    },
    {
        value: 'offline',
        label: 'Offline'
    }
];

export const Roles = [
    {
        value: 'admin',
        label: 'System Admin'
    },
    {
        value: 'store_owner',
        label: 'Store Owner'
    },
    {
        value: 'driver',
        label: 'Driver'
    },
    {
        value: 'factory_manager',
        label: 'Factory Manager'
    },
    {
        value: 'check_out_staff',
        label: 'Check-out Staff'
    },
    {
        value: 'check_in_staff',
        label: 'Check-in Staff'
    },
    {
        value: 'processing_staff',
        label: 'Processing Staff'
    }
];

export const HighlightType = [
    {
        value: 'none',
        label: 'None'
    },
    {
        value: 'highlight',
        label: 'Highlight'
    },
    {
        value: 'vip-highlight',
        label: 'Vip-Highlight'
    }
];

export const ContentTypes = [
    {
        value: 'video',
        label: 'Video'
    },
    {
        value: 'text',
        label: 'Script'
    },
    {
        value: 'image',
        label: 'Image'
    }
];

export const ContentLanguages = [
    {
        value: 'eng',
        label: 'English'
    },
    {
        value: 'chinese',
        label: 'Chinese'
    },
    {
        value: 'nil',
        label: 'Nil'
    }
];

export const VersionStatus = [
    {
        value: 'pending',
        label: 'Pending for publish'
    },
    {
        value: 'downloaded',
        label: 'Downloaded'
    },
    {
        value: 'downloading',
        label: 'Downloading'
    },
    {
        value: 'download_faield',
        label: 'Download Failed'
    },
    {
        value: 'published',
        label: 'Published'
    }
];

export const Supplier = [
    {
        value: 'AT&T',
        label: 'AT&T'
    }
];

export const DayOfWeek = [
    {
        value: '1',
        label: 'Monday'
    },
    {
        value: '2',
        label: 'Tuesday'
    },
    {
        value: '3',
        label: 'Wednesday'
    },
    {
        value: '4',
        label: 'Thursday'
    },
    {
        value: '5',
        label: 'Friday'
    },
    {
        value: '6',
        label: 'Saturday'
    },
    {
        value: '7',
        label: 'Sunday'
    }
];