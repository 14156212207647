// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import moment from "moment";

import {apiConfiguration} from "../../Api/_ApiConfiguration";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {connect} from "react-redux";

class ConfigurationView extends Component{

    state = {
        configuration: {
            configuration_id: null,
            description: '',
            fix_value: '',
            lastmoddate: null,
            name: '',
            percentage: '',
            sequence: null,
            type: '',
        }
    };

    componentDidMount() {
        const { history, i18n, id } = this.props;
        if (id == '39463468-7515-4232-a5a0-73ec0aca3335') {
            history.replace(`/${i18n.language}/recyclable`);
        }
        this.getConfigurationDetail();
    }

    getConfigurationDetail = () => {
        const { id, setBreadcrumbP } = this.props;
        if (id) {
            apiConfiguration.getConfigurationDetail(id).then(obj => {
                if(obj && obj.status === 200 && obj.data) {
                    const configItem = obj.data;
                    setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: 'Configuration',
                                    link: null
                                },
                                {
                                    title: configItem.name,
                                    link: null
                                }
                            ]
                        }
                    );
                    this.setState( {
                        configuration: configItem
                    })
                } else {
                    console.log("eCb : ", obj);
                }
            })
        } else {
            setBreadcrumbP(null);
        }
    }

    goToEditPage = () => {
        const { history, i18n, id } = this.props;
        history.push(`/${i18n.language}/configuration/${id}`);
    }

    back = () => {
        const { history, i18n } = this.props;
        history.push('/' + i18n.language + '/configuration?previous=true');
    }


    render(){
        const {t} = this.props;
        const {configuration} = this.state;
        return(
            <div>
                <div className="main__container flex-center-item">
                    <div className="full-width">
                        <Grid container xm={12} alignItems="center">
                            <Grid item xs={12} className="button-wrapper form-item pt20">
                                <Button type="button" className="primary-button" onClick={() => { this.goToEditPage() }}>{t("Common:Button.edit")}</Button>
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                Parameter
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {configuration.name}
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                Description
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {configuration.description}
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                Update Date
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                { configuration.lastmoddate ? moment(configuration.lastmoddate).format('YYYY-MM-DD') : ''}
                            </Grid>
                            <Grid item xs={2} className="form-item">
                                Fixed Value
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {configuration.fix_value}
                            </Grid>
                            {/* <Grid item xs={2} className="form-item">
                                Percentage
                            </Grid>
                            <Grid item xs={10} className="form-item">
                                {configuration.percentage}
                            </Grid> */}

                            <Grid item xs={2} className="form-item">
                            </Grid>
                            <Grid item xs={12} className="button-wrapper form-item pt20">
                                <Button type="button" className="primary-button" onClick={() => { this.back() }}>Back</Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfigurationView)));