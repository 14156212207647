// Essential for all components
import React, {Component, Fragment, createRef} from 'react';
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import { clone, get, map, reduce, isEqual } from 'lodash-es';
import moment from 'moment';
import Bluebird from 'bluebird';
import { Button } from '@material-ui/core';
import { AgGridReact } from '@ag-grid-community/react';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";
import {MySnackbarContentWrapper} from "../../components/102MaterialDesign/MySnackbarContentWrapper";
import Container from '@material-ui/core/Container';
import Snackbar from "@material-ui/core/Snackbar";
import { getDatasource, dateValueFormatter, booleanValueFormatter, defaultColumnDef } from '../../utils/AgGridUtils';

import memoizeOne from 'memoize-one';
import {apiOrder} from "../../Api/_ApiOrder";
import {apiStore} from "../../Api/_ApiStore";
import CommonUtils from '../../utils/CommonUtils';

const WEIGHT_ID_FIELD_NAME = 'order_item_id';

const weightColumnDefCache = {};
function getWeightColumnDefs(language, component) {
    let columnDefs = weightColumnDefCache[language];
    if (!columnDefs) {
        const storeTypeLabelAttr = language && language.startsWith('zh') ? 'label_zh' : 'label_en';
        columnDefs = [{
            field: 'order',
            headerName: 'ID',
            checkboxSelection: true,
            valueFormatter: function(params) {
                const value = get(params, ['value', 'order_number_displayed']);
                return value;
            }
        }, {
            field: 'order_item_sub_type',
            headerName: 'Recyclable',
            // editable: true,
            // singleClickEdit: true,
            valueFormatter: function(params) {
                const value = get(params, 'value');
                return value == null ? '' : get(component, ['state', 'orderItemSubTypeMap', value]);
            }
        }, {
            field: 'order',
            headerName: 'Location',
            valueFormatter: function(params) {
                const value = get(params, ['value', 'store']);
                return value == null ? '' : get(component, ['state', 'storeMap', value]);
            }
        }, {
            field: 'weight',
            headerName: 'Weight (kg)',
            editable: true,
            singleClickEdit: true,
            valueSetter: function(params) {
                const oldValue = params.oldValue;
                const newValue = params.newValue;
                const isValueChanged = oldValue != newValue;
                if (isValueChanged) {
                    params.data.weight = newValue;
                    const id = get(params, ['data', WEIGHT_ID_FIELD_NAME]);
                    apiOrder.updateOrderItem(id, {
                        weight: newValue
                    }).then((res) => {
                        if (res.status == 200 || res.status == 201) {
                            params.data.lastmoddate = res.data.lastmoddate;
                        } else {
                            params.data.weight = oldValue;
                        }
                        return component.gridApi.refreshCells();
                    });
                }
                return isValueChanged;
            }
        }, {
            field: 'quantity',
            headerName: 'Quantity',
            editable: true,
            singleClickEdit: true,
            valueSetter: function(params) {
                const oldValue = params.oldValue;
                const newValue = params.newValue;
                const isValueChanged = oldValue != newValue;
                if (isValueChanged) {
                    params.data.quantity = newValue;
                    const id = get(params, ['data', WEIGHT_ID_FIELD_NAME]);
                    apiOrder.updateOrderItem(id, {
                        quantity: newValue
                    }).then((res) => {
                        if (res.status == 200 || res.status == 201) {
                            params.data.lastmoddate = res.data.lastmoddate;
                        } else {
                            params.data.quantity = oldValue;
                        }
                        return component.gridApi.refreshCells();
                    });
                }
                return isValueChanged;
            }
        }, {
            field: 'order_images',
            headerName: 'Remark(s)',
            wrapText: true,
            autoHeight: true,
            equals: (left, right) => {
                if (left === right) {
                    return true;
                }
                if (left == right && left == null) {
                    return true;
                }
                if (left == null || right == null) {
                    return false;
                }
                if (left.length !== right.length) {
                    return false;
                }
                return isEqual(left, right);
            },
            cellRenderer: function(params) {
                return <Fragment>
                    <a
                        href="#"
                        onClick={(evt) => {
                            if (!component.state.isUploading) {
                                const elem = get(component, ['fileInput', 'current']);
                                if (elem) {
                                    const data = get(params, 'data');
                                    const id = get(data, WEIGHT_ID_FIELD_NAME);
                                    component.setState({
                                        order_item_id: id,
                                        upload_row_data: data
                                    })
                                    elem.click();
                                }
                            }
                        }}
                    >
                        <PublishIcon />
                    </a>
                    {map(params.value, (item) => {
                        const fileUrl = get(item, ['file', 'file_url']);
                        if (fileUrl) {
                            let fileName = get(item, ['file', 'file_name']);
                            if (!fileName) {
                                fileName = fileUrl.replace(/^.*\//, '');
                            }
                            return <Fragment>
                                <a href={fileUrl}>{fileName}</a>
                                {/* <a
                                    href="#"
                                    onClick={(evt) => {
                                        const id = get(item, 'order_image_id');
                                        apiReport.deleteOrderWeightSummaryFile(id).then(() => {
                                            remove(params.value, (item) => item.order_image_id == id);
                                            return component.gridApi.refreshCells();
                                        });
                                    }}
                                >
                                    <DeleteForeverIcon />
                                </a> */}
                            </Fragment>;
                        } else {
                            return '';
                        }
                    })}
                </Fragment>;
            }
        }, {
            field: 'lastmoddate',
            headerName: '最後更新',
            valueFormatter: dateValueFormatter
        }];
        weightColumnDefCache[language] = columnDefs;
    }
    return columnDefs;
}

function getWeightRowId(params) {
    return params.data[WEIGHT_ID_FIELD_NAME];
}


const CHECKOUT_QUANTITY_ID_FIELD_NAME = 'order_quantity_receipt_id';

const checkoutQuantityColumnDefCache = {};
function getCheckoutQuantityColumnDefs(language, component) {
    let columnDefs = checkoutQuantityColumnDefCache[language];
    if (!columnDefs) {
        const storeTypeLabelAttr = language && language.startsWith('zh') ? 'label_zh' : 'label_en';
        columnDefs = [{
            field: 'order',
            headerName: 'ID',
            checkboxSelection: true,
            valueFormatter: function(params) {
                const value = get(params, ['value', 'order_number_displayed']);
                return value;
            }
        }, {
            field: 'order_item_sub_type',
            headerName: 'Recyclable',
            // editable: true,
            // singleClickEdit: true,
            valueFormatter: function(params) {
                const value = get(params, 'value');
                return value == null ? '' : get(component, ['state', 'orderItemSubTypeMap', value]);
            }
        }, {
            field: 'store',
            headerName: 'Location',
            valueFormatter: function(params) {
                const value = get(params, 'value');
                return value == null ? '' : get(component, ['state', 'storeMap', value]);
            }
        }, {
            field: 'quantity',
            headerName: 'Quantity',
            editable: true,
            singleClickEdit: true,
            valueSetter: function(params) {
                const oldValue = params.oldValue;
                const newValue = params.newValue;
                const isValueChanged = oldValue != newValue;
                if (isValueChanged) {
                    params.data.quantity = newValue;
                    const id = get(params, ['data', CHECKOUT_QUANTITY_ID_FIELD_NAME]);
                    apiOrder.updateOrderItem(id, {
                        quantity: newValue
                    }).then((res) => {
                        if (res.status == 200 || res.status == 201) {
                            params.data.lastmoddate = res.data.lastmoddate;
                        } else {
                            params.data.quantity = oldValue;
                        }
                        return component.gridApi.refreshCells();
                    });
                }
                return isValueChanged;
            }
        }, {
            field: 'order_quantity_receipt_images',
            headerName: 'Remark(s)',
            wrapText: true,
            autoHeight: true,
            equals: (left, right) => {
                if (left === right) {
                    return true;
                }
                if (left == right && left == null) {
                    return true;
                }
                if (left == null || right == null) {
                    return false;
                }
                if (left.length !== right.length) {
                    return false;
                }
                return isEqual(left, right);
            },
            cellRenderer: function(params) {
                return <Fragment>
                    <a
                        href="#"
                        onClick={(evt) => {
                            if (!component.state.isUploading) {
                                const elem = get(component, ['fileInput', 'current']);
                                if (elem) {
                                    const data = get(params, 'data');
                                    const id = get(data, CHECKOUT_QUANTITY_ID_FIELD_NAME);
                                    component.setState({
                                        order_quantity_receipt_id: id,
                                        upload_row_data: data
                                    })
                                    elem.click();
                                }
                            }
                        }}
                    >
                        <PublishIcon />
                    </a>
                    {map(params.value, (item) => {
                        const fileUrl = get(item, ['file', 'file_url']);
                        if (fileUrl) {
                            let fileName = get(item, ['file', 'file_name']);
                            if (!fileName) {
                                fileName = fileUrl.replace(/^.*\//, '');
                            }
                            return <Fragment>
                                <a href={fileUrl}>{fileName}</a>
                                {/* <a
                                    href="#"
                                    onClick={(evt) => {
                                        const id = get(item, 'order_image_id');
                                        apiReport.deleteOrderWeightSummaryFile(id).then(() => {
                                            remove(params.value, (item) => item.order_image_id == id);
                                            return component.gridApi.refreshCells();
                                        });
                                    }}
                                >
                                    <DeleteForeverIcon />
                                </a> */}
                            </Fragment>;
                        } else {
                            return '';
                        }
                    })}
                </Fragment>;
            }
        }, {
            field: 'lastmoddate',
            headerName: '最後更新',
            valueFormatter: dateValueFormatter
        }];
        checkoutQuantityColumnDefCache[language] = columnDefs;
    }
    return columnDefs;
}

function getCheckoutQuantityRowId(params) {
    return params.data[CHECKOUT_QUANTITY_ID_FIELD_NAME];
}


const CHECKOUT_WEIGHT_ID_FIELD_NAME = 'order_weight_receipt_id';

const checkoutWeightColumnDefCache = {};
function getCheckoutWeightColumnDefs(language, component) {
    let columnDefs = checkoutWeightColumnDefCache[language];
    if (!columnDefs) {
        const storeTypeLabelAttr = language && language.startsWith('zh') ? 'label_zh' : 'label_en';
        columnDefs = [{
            field: 'order',
            headerName: 'ID',
            checkboxSelection: true,
            valueFormatter: function(params) {
                const value = get(params, ['value', 'order_number_displayed']);
                return value;
            }
        }, {
            field: 'order_item_sub_type',
            headerName: 'Recyclable',
            // editable: true,
            // singleClickEdit: true,
            valueFormatter: function(params) {
                const value = get(params, 'value');
                return value == null ? '' : get(component, ['state', 'orderItemSubTypeMap', value]);
            }
        }, {
            field: 'store',
            headerName: 'Location',
            valueFormatter: function(params) {
                const value = get(params, 'value');
                return value == null ? '' : get(component, ['state', 'storeMap', value]);
            }
        }, {
            field: 'weight',
            headerName: 'Weight (kg)',
            editable: true,
            singleClickEdit: true,
            valueSetter: function(params) {
                const oldValue = params.oldValue;
                const newValue = params.newValue;
                const isValueChanged = oldValue != newValue;
                if (isValueChanged) {
                    params.data.weight = newValue;
                    const id = get(params, ['data', CHECKOUT_WEIGHT_ID_FIELD_NAME]);
                    apiOrder.updateOrderItem(id, {
                        weight: newValue
                    }).then((res) => {
                        if (res.status == 200 || res.status == 201) {
                            params.data.lastmoddate = res.data.lastmoddate;
                        } else {
                            params.data.weight = oldValue;
                        }
                        return component.gridApi.refreshCells();
                    });
                }
                return isValueChanged;
            }
        }, {
            field: 'order_weight_receipt_images',
            headerName: 'Remark(s)',
            wrapText: true,
            autoHeight: true,
            equals: (left, right) => {
                if (left === right) {
                    return true;
                }
                if (left == right && left == null) {
                    return true;
                }
                if (left == null || right == null) {
                    return false;
                }
                if (left.length !== right.length) {
                    return false;
                }
                return isEqual(left, right);
            },
            cellRenderer: function(params) {
                return <Fragment>
                    <a
                        href="#"
                        onClick={(evt) => {
                            if (!component.state.isUploading) {
                                const elem = get(component, ['fileInput', 'current']);
                                if (elem) {
                                    const data = get(params, 'data');
                                    const id = get(data, CHECKOUT_WEIGHT_ID_FIELD_NAME);
                                    component.setState({
                                        order_weight_receipt_id: id,
                                        upload_row_data: data
                                    })
                                    elem.click();
                                }
                            }
                        }}
                    >
                        <PublishIcon />
                    </a>
                    {map(params.value, (item) => {
                        const fileUrl = get(item, ['file', 'file_url']);
                        if (fileUrl) {
                            let fileName = get(item, ['file', 'file_name']);
                            if (!fileName) {
                                fileName = fileUrl.replace(/^.*\//, '');
                            }
                            return <Fragment>
                                <a href={fileUrl}>{fileName}</a>
                                {/* <a
                                    href="#"
                                    onClick={(evt) => {
                                        const id = get(item, 'order_image_id');
                                        apiReport.deleteOrderWeightSummaryFile(id).then(() => {
                                            remove(params.value, (item) => item.order_image_id == id);
                                            return component.gridApi.refreshCells();
                                        });
                                    }}
                                >
                                    <DeleteForeverIcon />
                                </a> */}
                            </Fragment>;
                        } else {
                            return '';
                        }
                    })}
                </Fragment>;
            }
        }, {
            field: 'lastmoddate',
            headerName: '最後更新',
            valueFormatter: dateValueFormatter
        }];
        checkoutWeightColumnDefCache[language] = columnDefs;
    }
    return columnDefs;
}

function getCheckoutWeightRowId(params) {
    return params.data[CHECKOUT_WEIGHT_ID_FIELD_NAME];
}

const VIEW_DATA_WEIGHT = "w";
const VIEW_DATA_CHECKOUT_QUANTITY = "cq";
const VIEW_DATA_CHECKOUT_WEIGHT = "cw";

class Report1Management extends Component {

    constructor(props) {
        super(props);

        const { date } = this.props;
        const dateNum = +date;
        const fromMoment = moment(dateNum).utc(true).startOf('month').local(true);
        const toMoment = fromMoment.clone().endOf('month');
        const betweenRange = `${fromMoment.valueOf()},${toMoment.valueOf()}`;
        const tenantId = localStorage.getItem('asTenantId');

        this.fileInput = createRef();
        this.handleGridReady = this.handleGridReady.bind(this);
        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
        this.handleAddClicked = this.handleAddClicked.bind(this);
        this.handleEditClicked = this.handleEditClicked.bind(this);
        this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
        this.onFileSelected = this.onFileSelected.bind(this);
        this.handleFilterOrderItemSubTypeChanged = this.handleFilterOrderItemSubTypeChanged.bind(this);
        this.handleFilterStoreChanged = this.handleFilterStoreChanged.bind(this);
        this.setWeightDatasource = this.setWeightDatasource.bind(this);
        this.getWeightColumnDefs = memoizeOne(getWeightColumnDefs);
        this.setCheckoutQuantityDatasource = this.setCheckoutQuantityDatasource.bind(this);
        this.getCheckoutQuantityColumnDefs = memoizeOne(getCheckoutQuantityColumnDefs);
        this.setCheckoutWeightDatasource = this.setCheckoutWeightDatasource.bind(this);
        this.getCheckoutWeightColumnDefs = memoizeOne(getCheckoutWeightColumnDefs);
        this.handleViewDataChanged = this.handleViewDataChanged.bind(this);

        const defaultWeightDatasourceParams = {
            'order/store/tenant': tenantId,
            'createddate[between]': betweenRange,
            $expand: 'order_images/file,order'
        };
        const defaultCheckoutQuantityDatasourceParams = {
            'store/tenant': tenantId,
            'order/createddate[between]': betweenRange,
            $expand: 'order_quantity_receipt_images/file,order'
        };
        const defaultCheckoutWeightDatasourceParams = {
            'store/tenant': tenantId,
            'order/createddate[between]': betweenRange,
            $expand: 'order_weight_receipt_images/file,order'
        };
        this.state = {
            defaultWeightDatasourceParams: defaultWeightDatasourceParams,
            defaultCheckoutQuantityDatasourceParams: defaultCheckoutQuantityDatasourceParams,
            defaultCheckoutWeightDatasourceParams: defaultCheckoutWeightDatasourceParams,
            selectedRowCount: 0,
            dataSource: getDatasource('order_items', defaultWeightDatasourceParams),
            orderItemSubTypeMap: {},
            orderItemSubTypeOptions: [],
            filterOrderItemSubTypeOptions: [],
            filterOrderItemSubTypeValue: ' ',
            storeMap: {},
            storeOptions: [],
            filterStoreOptions: [],
            filterStoreValue: ' ',
            isUploading: false,
            viewData: 'w',
            columnDefs: this.getWeightColumnDefs(this.props.i18n.language, this),
            getRowIdFunc: getWeightRowId
        };
    }

    componentDidMount() {
        apiOrder.getOrderItemSubTypeList({
            'sequence[null]': false,
            $select: 'order_item_sub_type_id,label_zh',
            $orderby: 'sequence'
        }).then(res => {
            if (res.status == 200) {
                const orderItemSubTypeOptions = map(res.data, datum => {
                    const option = {
                        value: datum.order_item_sub_type_id,
                        label: datum.label_zh
                    };
                    return option;
                });
                const orderItemSubTypeMap = reduce(orderItemSubTypeOptions, (result, option) => {
                    result[option.value] = option.label;
                    return result;
                }, {});
                const filterOrderItemSubTypeOptions = map(orderItemSubTypeOptions, option => {
                    const filterOption = {
                        value: option.value,
                        label: 'Recyclable: ' + option.label
                    };
                    return filterOption;
                });
                filterOrderItemSubTypeOptions.unshift({
                    value: ' ',
                    label: 'Recyclable: All'
                });
                this.setState({
                    orderItemSubTypeMap,
                    orderItemSubTypeOptions,
                    filterOrderItemSubTypeOptions
                });
            }
        });
        apiStore.getStoreList({
            tenant: localStorage.getItem('asTenantId'),
            $select: 'store_id,name',
            $orderby: 'name'
        }).then(res => {
            if (res.status == 200) {
                const storeOptions = map(res.data, datum => {
                    const option = {
                        value: datum.store_id,
                        label: datum.name
                    };
                    return option;
                });
                const storeMap = reduce(storeOptions, (result, option) => {
                    result[option.value] = option.label;
                    return result;
                }, {});
                const filterStoreOptions = map(storeOptions, option => {
                    const filterOption = {
                        value: option.value,
                        label: 'Location: ' + option.label
                    };
                    return filterOption;
                });
                filterStoreOptions.unshift({
                    value: ' ',
                    label: 'Location: All'
                });
                this.setState({
                    storeMap,
                    storeOptions,
                    filterStoreOptions
                });
            }
        });
    }

    onFileSelected(evt) {
        const elem = get(this, ['fileInput', 'current']);
        if (elem) {
            this.setState({
                isUploading: true
            });
            const files = elem.files;
            if (files && files.length > 0) {
                /*
                let summaryId = this.state.order_weight_summary_id;
                if (!summaryId) {
                    const { upload_row_data } = this.state;
                    summaryId = apiReport.addOrderWeightSummary({
                        record_date: get(upload_row_data, 'date'),
                        weight: 0,
                        tenant: localStorage.getItem('asTenantId'),
                        order_item_sub_type: get(upload_row_data, 'typeId'),
                        factory: get(upload_row_data, 'factoryId')
                    }).then(res => {
                        if (res.status == 200 || res.status == 201) {
                            return res.data.order_weight_summary_id;
                        } else {
                            throw res;
                        }
                    });
                }
                const promise = Bluebird.resolve(summaryId).then((summaryId) => {
                    return Bluebird.map(files, file => {
                        return CommonUtils.readFile(file).then(base64Result => {
                            return apiReport.addOrderWeightSummaryFile({
                                order_weight_summary: summaryId,
                                file: {
                                    file_url: `Content-Type:${file.type || 'application/octet-stream'}\r\nContent-Disposition:attachment;filename="${encodeURI(file.name)}"\r\nContent-Transfer-Encoding: base64\r\n\r\n${base64Result.split('base64,')[1]}`,
                                }
                            });
                        })
                    }, {
                        concurrency: 1
                    });
                });
                promise.then(() => {
                    elem.value = '';
                    this.setState({
                        isUploading: false,
                        order_weight_summary_id: null
                    });
                    return this.fetchRowData();
                });
                */
            }
        }
    }

    handleGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    handleSelectionChanged(evt) {
        const selectedRowCount = evt.api.getSelectedNodes().length;
        this.setState({
            selectedRowCount
        });
    }

    handleAddClicked() {
        const { i18n } = this.props;
        this.props.history.push('/' + encodeURIComponent(i18n.language) + '/store');
    }

    handleEditClicked() {
        if (!this.gridApi) {
            return;
        }
        const selectedRowNodes = this.gridApi.getSelectedNodes();
        if (!selectedRowNodes || selectedRowNodes.length !== 1) {
            return;
        }
        const id = selectedRowNodes[0].data[WEIGHT_ID_FIELD_NAME];
        const { i18n } = this.props;
        this.props.history.push('/' + encodeURIComponent(i18n.language) + '/store/' + encodeURIComponent(id));
    }

    handleDeleteClicked() {
        if (!this.gridApi) {
            return;
        }
        const selectedRowNodes = this.gridApi.getSelectedNodes();
        if (!selectedRowNodes || !selectedRowNodes.length) {
            return;
        }
        return Bluebird.map(selectedRowNodes, (selectedRowNode) => {
            const id = get(selectedRowNode, ['data', WEIGHT_ID_FIELD_NAME]);
            if (id != null) {
                return apiStore.deleteStore(id);
            }
        }, {
            concurrency: 4
        }).then(() => {
            this.setState({
                openSnackbar: true,
                messageSnackbar: 'Delete store(s) successfully',
                variantSnackbar: 'success'
            });
        }).catch(console.error);
    }

    handleLogout() {
        this.props.logoutP();
    }

    handleCloseSnackbar() {
        this.setState({ openSnackbar: false });
    };

    handleFilterOrderItemSubTypeChanged(evt) {
        const viewData = this.state.viewData;
        const oldValue = this.state.filterOrderItemSubTypeValue;
        const newValue = evt.target.value;
        this.setState({
            filterOrderItemSubTypeValue: newValue
        }, () => {
            if (oldValue != newValue) {
                switch (viewData) {
                    case VIEW_DATA_CHECKOUT_QUANTITY:
                        this.setCheckoutQuantityDatasource();
                        break;
                    case VIEW_DATA_CHECKOUT_WEIGHT:
                        this.setCheckoutWeightDatasource();
                        break;
                    case VIEW_DATA_WEIGHT:
                    default:
                        this.setWeightDatasource();
                        break;
                }
            }
        });
    }

    handleFilterStoreChanged(evt) {
        const viewData = this.state.viewData;
        const oldValue = this.state.filterStoreValue;
        const newValue = evt.target.value;
        this.setState({
            filterStoreValue: newValue
        }, () => {
            if (oldValue != newValue) {
                switch (viewData) {
                    case VIEW_DATA_CHECKOUT_QUANTITY:
                        this.setCheckoutQuantityDatasource();
                        break;
                    case VIEW_DATA_CHECKOUT_WEIGHT:
                        this.setCheckoutWeightDatasource();
                        break;
                    case VIEW_DATA_WEIGHT:
                    default:
                        this.setWeightDatasource();
                        break;
                }
            }
        });
    }

    setWeightDatasource() {
        const { defaultWeightDatasourceParams, filterOrderItemSubTypeValue, filterStoreValue } = this.state;
        const datasourceParams = clone(defaultWeightDatasourceParams);
        if (filterOrderItemSubTypeValue && filterOrderItemSubTypeValue != ' ') {
            datasourceParams.order_item_sub_type = filterOrderItemSubTypeValue;
        }
        if (filterStoreValue && filterStoreValue != ' ') {
            datasourceParams['order/store'] = filterStoreValue;
        }
        const datasource = getDatasource('order_items', datasourceParams);
       this.setState({
            dataSource: datasource,
        });
        this.gridApi.setDatasource(datasource);
    }

    setCheckoutQuantityDatasource() {
        const { defaultCheckoutQuantityDatasourceParams, filterOrderItemSubTypeValue, filterStoreValue } = this.state;
        const datasourceParams = clone(defaultCheckoutQuantityDatasourceParams);
        if (filterOrderItemSubTypeValue && filterOrderItemSubTypeValue != ' ') {
            datasourceParams.order_item_sub_type = filterOrderItemSubTypeValue;
        }
        if (filterStoreValue && filterStoreValue != ' ') {
            datasourceParams.store = filterStoreValue;
        }
        const datasource = getDatasource('order_quantity_receipts', datasourceParams);
        this.setState({
            dataSource: datasource,
        });
        this.gridApi.setDatasource(datasource);
    }

    setCheckoutWeightDatasource() {
        const { defaultCheckoutWeightDatasourceParams, filterOrderItemSubTypeValue, filterStoreValue } = this.state;
        const datasourceParams = clone(defaultCheckoutWeightDatasourceParams);
        if (filterOrderItemSubTypeValue && filterOrderItemSubTypeValue != ' ') {
            datasourceParams.order_item_sub_type = filterOrderItemSubTypeValue;
        }
        if (filterStoreValue && filterStoreValue != ' ') {
            datasourceParams.store = filterStoreValue;
        }
        const datasource = getDatasource('order_weight_receipts', datasourceParams);
        this.setState({
            dataSource: datasource,
        });
        this.gridApi.setDatasource(datasource);
    }

    handleViewDataChanged(evt) {
        const viewData = evt.target.value;
        let getRowIdFunc;
        let columnDefs;
        let changeDatasourceFunc;
        switch (viewData) {
            case VIEW_DATA_CHECKOUT_QUANTITY:
                getRowIdFunc = getCheckoutQuantityRowId;
                columnDefs = this.getCheckoutQuantityColumnDefs(this.props.i18n.language, this);
                changeDatasourceFunc = this.setCheckoutQuantityDatasource;
                break;
            case VIEW_DATA_CHECKOUT_WEIGHT:
                getRowIdFunc = getCheckoutWeightRowId;
                columnDefs = this.getCheckoutWeightColumnDefs(this.props.i18n.language, this);
                changeDatasourceFunc = this.setCheckoutWeightDatasource;
                break;
            case VIEW_DATA_WEIGHT:
            default:
                getRowIdFunc = getWeightRowId;
                columnDefs = this.getWeightColumnDefs(this.props.i18n.language, this);
                changeDatasourceFunc = this.setWeightDatasource;
                break;
        }
        this.setState({
            viewData: viewData,
            getRowIdFunc: getRowIdFunc,
            columnDefs: columnDefs
        });
        this.gridApi.setGetRowId(getRowIdFunc);
        this.gridApi.setColumnDefs(columnDefs);
        changeDatasourceFunc.call(this);
    }

    render() {
        const {
            dataSource,
            selectedRowCount,
            openSnackbar,
            variantSnackbar,
            messageSnackbar,
            isUploading,
            filterOrderItemSubTypeOptions,
            filterOrderItemSubTypeValue,
            filterStoreOptions,
            filterStoreValue,
            viewData,
            columnDefs,
            getRowIdFunc
        } = this.state;
        return <Container>
            <h2>Daily Collection Record of the Recycle Stores</h2>
            <div className="data-grid-menu">
                {/* <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="primary"
                    onClick={this.handleAddClicked}
                >
                    添加
                </Button>
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="primary"
                    onClick={this.handleEditClicked}
                    disabled={selectedRowCount !== 1}
                >
                    編輯
                </Button>
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="secondary"
                    onClick={this.handleDeleteClicked}
                    disabled={selectedRowCount === 0}
                >
                    刪除
                </Button> */}
                <Select
                    value={filterOrderItemSubTypeValue}
                    onChange={this.handleFilterOrderItemSubTypeChanged}
                >
                    {map(filterOrderItemSubTypeOptions, option => {
                        const value = option.value;
                        return <MenuItem
                            key={value}
                            value={value}
                        >
                            {option.label}
                        </MenuItem>;
                    })}
                </Select>
                <Select
                    value={filterStoreValue}
                    onChange={this.handleFilterStoreChanged}
                >
                    {map(filterStoreOptions, option => {
                        const value = option.value;
                        return <MenuItem
                            key={value}
                            value={value}
                        >
                            {option.label}
                        </MenuItem>;
                    })}
                </Select>
                <Select
                    value={viewData}
                    onChange={this.handleViewDataChanged}
                >
                    <MenuItem
                        value={VIEW_DATA_WEIGHT}
                    >
                        磅重記錄
                    </MenuItem>
                    <MenuItem
                        value={VIEW_DATA_CHECKOUT_QUANTITY}
                    >
                        出貨記錄 (數量)
                    </MenuItem>
                    <MenuItem
                        value={VIEW_DATA_CHECKOUT_WEIGHT}
                    >
                        出貨記錄 (重量)
                    </MenuItem>
                </Select>
            </div>
            <AgGridReact
                className="data-grid ag-theme-alpine"
                onGridReady={this.handleGridReady}
                pagination="true"
                rowSelection="multiple"
                onSelectionChanged={this.handleSelectionChanged}
                rowModelType="infinite"
                getRowId={getRowIdFunc}
                defaultColDef={defaultColumnDef}
                columnDefs={columnDefs}
                datasource={dataSource}
            ></AgGridReact>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={this.handleCloseSnackbar}
            >
                <MySnackbarContentWrapper
                    onClose={this.handleCloseSnackbar}
                    variant={variantSnackbar}
                    message={messageSnackbar}
                />
            </Snackbar>
            <input
                type="file"
                ref={this.fileInput}
                hidden
                multiple
                disabled={isUploading}
                onChange={this.onFileSelected}
            />
        </Container>;
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Report1Management)));