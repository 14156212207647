// Essential for all components
import React, {Component, Fragment, createRef} from 'react';
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import { get, forEach, values, reduce, map, remove, isEqual } from 'lodash-es';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";
import Container from '@material-ui/core/Container';
import { getDatasource, defaultColumnDef, dateValueFormatter } from '../../utils/AgGridUtils';

import memoizeOne from 'memoize-one';
import {apiOrder} from "../../Api/_ApiOrder";
import {apiReport} from '../../Api/_ApiReport';
import {apiFactory} from '../../Api/_ApiFactory';
import {saveAs} from 'file-saver';
import Bluebird from 'bluebird';
import CommonUtils from '../../utils/CommonUtils';

const ID_FIELD_NAME = 'id';

const SAMPLE_DATA = [{
    id: '1',
    recyclable_name: '膠樽',
    processor_name: '劉財記',
    weight_record: 34,
    weight_checkout_record: 34,
    weight_receipt_record: null,
    receipts: [],
    lastmoddate: null,
}];

function getColumnDefs(language, component) {
    const columnDefs = [{
        field: 'recyclable_name',
        headerName: 'Recyclable',
    }, {
        field: 'processor_name',
        headerName: 'Processor',
    }, {
        field: 'weight_record',
        headerName: '磅重記錄',
    }, {
        field: 'weight_checkout_record',
        headerName: '出貨記錄',
    }, {
        field: 'weight_receipt_record',
        headerName: '收據記錄 (kg)',
        editable: function(params) {
            return params.data.factoryId;
        },
        singleClickEdit: true,
        valueSetter: function(params) {
            const oldValue = params.oldValue;
            const newValue = params.newValue;
            const isValueChanged = oldValue != newValue;
            if (isValueChanged) {
                params.data.weight_receipt_record = newValue;
                let summaryId = get(params, ['data', 'order_weight_summary_id']);
                let promise;
                if (summaryId) {
                    promise = apiReport.updateOrderWeightSummary(summaryId, {
                        weight: +newValue,
                    });
                } else {
                    promise = apiReport.addOrderWeightSummary({
                        record_date: get(params, ['data', 'date']),
                        weight: newValue,
                        tenant: localStorage.getItem('asTenantId'),
                        order_item_sub_type: get(params, ['data', 'typeId']),
                        factory: get(params, ['data', 'factoryId'])
                    });
                }
                promise.then((res) => {
                    if (res.status == 200 || res.status == 201) {
                        params.data.lastmoddate = res.data.lastmoddate;
                    } else {
                        params.data.weight_receipt_record = oldValue;
                    }
                    return component.gridApi.refreshCells();
                });
            }
            return isValueChanged;
        }
    }, {
        field: 'order_weight_summary_files',
        headerName: 'Receipt(s)',
        wrapText: true,
        autoHeight: true,
        equals: (left, right) => {
            if (left === right) {
                return true;
            }
            if (left == right && left == null) {
                return true;
            }
            if (left == null || right == null) {
                return false;
            }
            if (left.length !== right.length) {
                return false;
            }
            return isEqual(left, right);
        },
        cellRenderer: function(params) {
            return <Fragment>
                <a
                    href="#"
                    onClick={(evt) => {
                        if (!component.state.isUploading) {
                            const elem = get(component, ['fileInput', 'current']);
                            if (elem) {
                                const data = get(params, 'data');
                                const id = get(data, 'order_weight_summary_id');
                                component.setState({
                                    order_weight_summary_id: id,
                                    upload_row_data: data
                                })
                                elem.click();
                            }
                        }
                    }}
                >
                    <PublishIcon />
                </a>
                {map(params.value, (item) => {
                    const fileUrl = get(item, ['file', 'file_url']);
                    if (fileUrl) {
                        let fileName = get(item, ['file', 'file_name']);
                        if (!fileName) {
                            fileName = fileUrl.replace(/^.*\//, '');
                        }
                        return <Fragment>
                            <a href={fileUrl}>{fileName}</a>
                            <a
                                href="#"
                                onClick={(evt) => {
                                    const id = get(item, 'order_weight_summary_file_id');
                                    apiReport.deleteOrderWeightSummaryFile(id).then(() => {
                                        remove(params.value, (item) => item.order_weight_summary_file_id == id);
                                        return component.gridApi.refreshCells();
                                    });
                                }}
                            >
                                <DeleteForeverIcon />
                            </a>
                        </Fragment>;
                    } else {
                        return '';
                    }
                })}
            </Fragment>;
        }
    }, {
        field: 'lastmoddate',
        headerName: 'Last Updated',
        cellRenderer: dateValueFormatter
    }];
    return columnDefs;
}

// const REPORT_TYPE_NUMBER_MAP = {
//     '15e32046-7f60-444b-9c1f-fd3b1d8a36be': 1,
//     '665aadff-018e-432d-8c6c-e5ae935fbd41': 2,
//     'a4e7158c-2340-4012-81ca-d7be4234ddc8': 3,
//     'a9b00935-ba0d-480c-81be-4911112a43a1': 4,
//     'cd01c5b4-bb6e-44e6-85d5-e33db9dabb9b': 5
// }
// function getReportNumber(reportTypeId) {
//     return REPORT_TYPE_NUMBER_MAP[reportTypeId];
// }
function getDataSource(reportTypeId) {
    let datasource;
    // if (REPORT_TYPE_NUMBER_MAP.hasOwnProperty(reportTypeId)) {
    //     const reportTypeNumber = getReportNumber(reportTypeId);
    //     datasource = getDatasource(`report_${reportTypeNumber}_date_ranges`);
    // }
    datasource = getDatasource('report_date_ranges');
    return datasource;
}

function getRowId(params) {
    return get(params, ['data', ID_FIELD_NAME]);
}

const ORDER_STATUS_ACCEPT_ORDER_CANCELLED_ID = '3283001e-f174-4c09-8f61-a2ad74e495b9';

class Report3Management extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            gridReady: false,
            reactReady: false,
            isUploading: false,
            order_weight_summary_id: null,
        };
        this.fileInput = createRef();
        this.handleGridReady = this.handleGridReady.bind(this);
        this.fetchRowData = this.fetchRowData.bind(this);
        this.onFileSelected = this.onFileSelected.bind(this);
        this.getColumnDefs = memoizeOne(getColumnDefs);
    }

    handleGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        const { reactReady } = this.state;
        this.setState({ gridReady: true });
        if (reactReady) {
            this.fetchRowData();
        }
    }

    fetchRowData() {
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay();
        }
        const { date } = this.props;
        const dateNum = +date;
        const dataMap = {};
        const fromMoment = moment(dateNum).utc(true).startOf('month').local(true);
        const toMoment = fromMoment.clone().endOf('month');
        const betweenRange = `${fromMoment.valueOf()},${toMoment.valueOf()}`;
        const tenantId = localStorage.getItem('asTenantId');

        const factoryMapPromise = apiFactory.getList().then(res => {
            if (res.status == 200) {
                const factoryMap = reduce(res.data, (factoryMap, value) => {
                    factoryMap[value.factory_id] = value;
                    return factoryMap;
                }, {});
                return factoryMap;
            } else {
                throw res;
            }
        });
        const orderItemSubTypeMapPromise = apiOrder.getOrderItemSubTypeList().then(res => {
            if (res.status == 200) {
                const orderItemSubTypeMap = reduce(res.data, (orderItemSubTypeMap, value) => {
                    orderItemSubTypeMap[value.order_item_sub_type_id] = value;
                    return orderItemSubTypeMap;
                }, {});
                return orderItemSubTypeMap;
            } else {
                throw res;
            }
        });
        const mapDataPromise = Promise.all([
            factoryMapPromise,
            orderItemSubTypeMapPromise
        ]);
        return Promise.all([
            mapDataPromise.then(mapData => {
                return apiReport.getOrderItemList({
                    'store/tenant': tenantId,
                    'createddate[between]': betweenRange,
                    $expand: 'order'
                }).then(res => {
                    if (res.status == 200) {
                        const factoryMap = mapData[0];
                        const orderItemSubTypeMap = mapData[1];
                        forEach(res.data, srcDatum => {
                            const typeId = get(srcDatum, 'order_item_sub_type');
                            const factoryId = get(srcDatum, ['order', 'factory']);
                            const weight = get(srcDatum, 'weight');
                            if (typeId && weight) {
                                const id = `${typeId}_${factoryId || 'undefined'}`;
                                const datum = dataMap[id];
                                if (datum) {
                                    datum.weight_record = (datum.weight_record || 0) + weight;
                                } else {
                                    const processorName = factoryId ? get(factoryMap, [factoryId, 'name']) : '未出貨';
                                    dataMap[id] = {
                                        id,
                                        date: fromMoment.valueOf(),
                                        typeId,
                                        factoryId,
                                        recyclable_name: get(orderItemSubTypeMap, [typeId, 'label_zh']),
                                        processor_name: processorName,
                                        weight_record: weight
                                    };
                                }
                            }
                        });
                    }
                });
            }),

            mapDataPromise.then(mapData => {
                return apiReport.getOrderWeightReceiptList({
                    'store/tenant': tenantId,
                    'order/createddate[between]': betweenRange,
                    $expand: 'order'
                }).then(res => {
                    if (res.status == 200) {
                        const factoryMap = mapData[0];
                        const orderItemSubTypeMap = mapData[1];
                        forEach(res.data, srcDatum => {
                            const typeId = get(srcDatum, 'order_item_sub_type');
                            const factoryId = get(srcDatum, ['order', 'factory']);
                            const weight = get(srcDatum, 'weight');
                            const orderStatusId = get(srcDatum, ['order', 'order_status']);
                            if (typeId && weight && orderStatusId != ORDER_STATUS_ACCEPT_ORDER_CANCELLED_ID) {
                                const id = `${typeId}_${factoryId || 'undefined'}`;
                                const datum = dataMap[id];
                                if (datum) {
                                    datum.weight_checkout_record = (datum.weight_checkout_record || 0) + weight;
                                } else {
                                    const processorName = factoryId ? get(factoryMap, [factoryId, 'name']) : '未出貨';
                                    dataMap[id] = {
                                        id,
                                        date: fromMoment.valueOf(),
                                        typeId,
                                        factoryId,
                                        recyclable_name: get(orderItemSubTypeMap, [typeId, 'label_zh']),
                                        processor_name: processorName,
                                        weight_checkout_record: weight
                                    };
                                }
                            }
                        });
                    }
                });
            }),

            mapDataPromise.then(mapData => {
                return apiReport.getOrderWeightSummaryList({
                    tenant: tenantId,
                    'record_date[between]': betweenRange,
                    $expand: 'order_weight_summary_files/file'
                }).then(res => {
                    if (res.status == 200) {
                        const factoryMap = mapData[0];
                        const orderItemSubTypeMap = mapData[1];
                        forEach(res.data, srcDatum => {
                            const typeId = get(srcDatum, 'order_item_sub_type');
                            const factoryId = get(srcDatum, 'factory');
                            const weight = get(srcDatum, 'weight');
                            const order_weight_summary_files = get(srcDatum, 'order_weight_summary_files');
                            if (typeId) {
                                const id = `${typeId}_${factoryId || 'undefined'}`;
                                const datum = dataMap[id];
                                if (datum) {
                                    datum.order_weight_summary_id = srcDatum.order_weight_summary_id;
                                    datum.lastmoddate = srcDatum.lastmoddate;
                                    datum.weight_receipt_record = (datum.weight_receipt_record || 0) + weight;
                                    if (datum.debug_order_weight_summary_id) {
                                        datum.debug_order_weight_summary_id.push(srcDatum.order_weight_summary_id);
                                    } else {
                                        datum.debug_order_weight_summary_id = [srcDatum.order_weight_summary_id];
                                    }
                                    datum.order_weight_summary_files = order_weight_summary_files
                                } else {
                                    const processorName = factoryId ? get(factoryMap, [factoryId, 'name']) : '未出貨';
                                    dataMap[id] = {
                                        id,
                                        date: fromMoment.valueOf(),
                                        typeId,
                                        factoryId,
                                        recyclable_name: get(orderItemSubTypeMap, [typeId, 'label_zh']),
                                        processor_name: processorName,
                                        weight_receipt_record: weight,
                                        order_weight_summary_id: srcDatum.order_weight_summary_id,
                                        order_weight_summary_files: order_weight_summary_files,
                                        lastmoddate: srcDatum.lastmoddate,
                                        debug_order_weight_summary_id: [srcDatum.order_weight_summary_id]
                                    };
                                }
                            }
                        });
                    }
                });
            })
        ]).then(() => {
            const rowData = values(dataMap);
            this.setState({rowData});
            if (this.gridApi) {
                this.gridApi.hideOverlay();
            }
            return rowData;
        });
    }

    onFileSelected(evt) {
        const elem = get(this, ['fileInput', 'current']);
        if (elem) {
            this.setState({
                isUploading: true
            });
            const files = elem.files;
            if (files && files.length > 0) {
                let summaryId = this.state.order_weight_summary_id;
                if (!summaryId) {
                    const { upload_row_data } = this.state;
                    const factoryId = get(upload_row_data, 'factoryId');
                    if (!factoryId) {
                        return;
                    }
                    summaryId = apiReport.addOrderWeightSummary({
                        record_date: get(upload_row_data, 'date'),
                        weight: 0,
                        tenant: localStorage.getItem('asTenantId'),
                        order_item_sub_type: get(upload_row_data, 'typeId'),
                        factory: factoryId
                    }).then(res => {
                        if (res.status == 200 || res.status == 201) {
                            return res.data.order_weight_summary_id;
                        } else {
                            throw res;
                        }
                    });
                }
                const promise = Bluebird.resolve(summaryId).then((summaryId) => {
                    return Bluebird.map(files, file => {
                        return CommonUtils.readFile(file).then(base64Result => {
                            return apiReport.addOrderWeightSummaryFile({
                                order_weight_summary: summaryId,
                                file: {
                                    file_url: `Content-Type:${file.type || 'application/octet-stream'}\r\nContent-Disposition:attachment;filename="${encodeURI(file.name)}"\r\nContent-Transfer-Encoding: base64\r\n\r\n${base64Result.split('base64,')[1]}`,
                                }
                            });
                        })
                    }, {
                        concurrency: 1
                    });
                });
                promise.then(() => {
                    elem.value = '';
                    this.setState({
                        isUploading: false,
                        order_weight_summary_id: null
                    });
                    return this.fetchRowData();
                });
            }
        }
    }

    componentDidMount() {
        const { gridReady } = this.state;
        this.setState({ reactReady: true });
        if (gridReady) {
            this.fetchRowData();
        }
    }

    render() {
        const { i18n, date } = this.props;
        const { rowData, isUploading } = this.state;
        const dateNum = +date;
        const fromMoment = moment(dateNum).utc(true).startOf('month').local(true);
        return <Container>
            <h2>Report {fromMoment.format('YYYY-MM')}</h2>
            <AgGridReact
                className="data-grid ag-theme-alpine"
                onGridReady={this.handleGridReady}
                pagination="true"
                getRowId={getRowId}
                defaultColDef={defaultColumnDef}
                columnDefs={this.getColumnDefs(i18n.language, this)}
                rowData={rowData}
            ></AgGridReact>
            <input
                type="file"
                ref={this.fileInput}
                hidden
                multiple
                disabled={isUploading}
                onChange={this.onFileSelected}
            />
        </Container>;
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Report3Management)));