import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import withStyles from '@material-ui/styles/withStyles';

const styles = theme => ({
  dialogTitle: {
      backgroundColor: '#FF0102',
      '& > *': {
          color: '#FFF',
          justifyContent: 'center',
          textAlign: 'center',
      },
  },
  dialogContent: {
      padding: '24px 36px',
  },
  dialogContentText: {
      color: '#000',
  },
  dialogActions: {
      backgroundColor: '#F3F2F2',
      padding: 16,
      '& button': {
          margin: 0,
      }
  },
  cancelBtn: {
      backgroundColor: '#FFF',
      border: '1px solid #E0E0E0',
      color: '#A301D9 !important',
  },
});

const AlertDialog = ({ isOpen,
  title, children, onAction, onCancel, actionButtonText, cancelButtonText, actionButtonClass, classes, ...props }) => {
  return (
    <Dialog open={isOpen} onBackdropClick={onCancel} keepMounted {...props}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
              {children}
          </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
          <Grid className="button-wrapper" container justify="space-between">
              <Button type="button" className={classes.cancelBtn} onClick={onCancel}>
                  {cancelButtonText}
              </Button>
              <Button type="button" className={actionButtonClass} onClick={onAction}>
                  {actionButtonText}
              </Button>
          </Grid>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
  actionButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  actionButtonClass: PropTypes.string,
  classes: PropTypes.object,
};

AlertDialog.defaultProps = {
  isOpen: false,
  title: '',
  children: null,
  onAction: () => {},
  onCancel: () => {},
  actionButtonText: 'OK',
  cancelButtonText: 'Cancel',
  actionButtonClass: 'primary-button',
  classes: {},
};

export default withStyles(styles)(AlertDialog);
