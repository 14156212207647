import {api} from "./_ApiFactoryWithHeader";

export const apiMessage = {
    getPushNotificationTemplates: (params) => {
        return api.get('push_notification_templates?send_type%5Bin%5D=repeat,schedule', params, null)
    },
    getPushNotificationTemplatesDetail: (id, params) => {
        return api.get(`push_notification_templates/${id}`, params, null)
    },
    updatePushNotificationTemplates: (id, body) => {
        return api.put(`push_notification_templates/${id}`, body)
    },
    deletePushNotificationTemplates: (ids, params) => {
        return api.delete(`push_notification_templates?push_notification_template_id%5Bin%5D=${ids}`, params, null)
    },
    pushNotification: (body) => {
        return api.post(`push_notification_templates`, body)
    },
    getWechatTemplates: (params) => {
        return api.get('wechat_templates', params, null)
    },
    sendWechatToAll: (body) => {
        return api.post(`wechat_sent_to_all`, body)
    },
};
