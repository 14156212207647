import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import ErrorMessage from '../../components/100Include/ErrorMessage';
import { addMessage } from "../../Redux/Action/messageAction";
import { setBreadcrumb } from "../../Redux/Action/breadcrumbAction";
import { downLoadFile } from '../../utils/Utility';
import { apiFile } from "../../Api/_ApiFiles";

class AuctionFileUpdate extends React.Component {
    constructor() {
        super();
        this.state = {
            file: null,
        };
    }

    setFile = (file) => {
        this.setState({ file });
    }

    onClickCancel = () => {
        const { history, i18n } = this.props;
        const { file } = this.state;
        history.push('/' + i18n.language + '/auction-management/' + get(file, 'auction_slot.auction_slot_id'));
    }

    updateAuctionFile = (values, opt) => {
        const { id, history, i18n, addMessageP } = this.props;
        const { file } = this.state;
        const updateBody = {
            file_name: values.file_name,
        };
        return apiFile.updateFile(id, updateBody).then(res => {
            if (res && res.status === 200) {
                history.push('/' + i18n.language + '/auction-management/' + get(file, 'auction_slot.auction_slot_id'));
            } else {
                const error = res.data && res.data.error ? res.data.error : 'Update Auction File failed';
                throw new Error(error);
            }
        }).catch(err => {
            const msgDsp = {
                messageSnackbar: err.message,
                variantSnackbar: 'error',
                key: new Date().getTime(),
            };
            addMessageP(msgDsp);
        });
    }

    onClickDownloadFile = (file) => {
        return fetch(get(file, 'file_url'))
            .then(res => res.blob())
            .then(blobData => {
                downLoadFile(blobData, get(file, 'file_name'));
            });
    }

    formConfiguration = ({ values, errors, touched, handleChange }) => {
        const { t } = this.props;
        const { file } = this.state;
        return (
            <Form id="fileForm" className=" full-width">
                <Grid container xm={12} classes={{ item: 'form-item' }}>
                <Grid item xs={2} className="form-item pt20">
                        File
                    </Grid>
                    <Grid item xs={10} className="form-item pt20">
                        <Link
                            className="custom-link"
                            onClick={() => this.onClickDownloadFile(file)}
                            underline="always"
                        >
                            Click to Download
                        </Link>
                    </Grid>
                    <Grid item xs={2} className="form-item pt20">
                        File Size
                    </Grid>
                    <Grid item xs={10} className="form-item pt20">
                        {get(file, 'file_size')} bytes
                    </Grid>
                    <Grid item xs={2} className="form-item pt20">
                        File Name
                    </Grid>
                    <Grid item xs={10} className="form-item pt20">
                        <input type="text"
                            name="file_name"
                            onChange={handleChange}
                            value={values.file_name}
                        />
                        <Grid item xs={12}>
                            {errors.file_name && touched.file_name ? <ErrorMessage message={errors.file_name} /> : t("Common:Form.validator.required")}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">Save</Button>
                        <Button type="button" className="second-button" onClick={this.onClickCancel}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    getAuctionFileDetail = () => {
        const { id, i18n, setBreadcrumbP, addMessageP } = this.props;
        if (id) {
            const fileParams = {
                '$expand': 'auction_slot',
            }
            apiFile.getFile(id, fileParams).then(res => {
                if (res && res.status === 200) {
                    const { data: file } = res;
                    this.setFile(file);
                    setBreadcrumbP({
                        breadcrumbData: [
                            {
                                title: `Auction [${get(file, 'auction_slot.name')}]`,
                                link: '/' + i18n.language + '/auction-management/' + get(file, 'auction_slot.auction_slot_id'),
                            },
                            {
                                title: `Edit File`,
                                link: null,
                            }
                        ]
                    });
                } else {
                    const error = res.data && res.data.error ? res.data.error : 'Get Auction File failed';
                    throw new Error(error);
                }
            }).catch(err => {
                const msgDsp = {
                    messageSnackbar: err.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                addMessageP(msgDsp);
            });
        }
    }

    componentDidMount() {
        this.getAuctionFileDetail();
    }

    render() {
        const { file } = this.state;
        const initialValues = {
            file_name: get(file, 'file_name') || '',
        };
        const Schema = Yup.object().shape({
            file_name: Yup.string().required("File name is required"),
        });
        return (
            <div className="main__container flex-center-item">
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={Schema}
                    onSubmit={this.updateAuctionFile}
                    component={this.formConfiguration}
                />
            </div>
        );

    }
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});

AuctionFileUpdate.propTypes = {
    id: PropTypes.string,
    setBreadcrumbP: PropTypes.func,
    addMessageP: PropTypes.func,
    history: PropTypes.object,
    i18n: PropTypes.object,
};

AuctionFileUpdate.defaultProps = {
    id: null,
    setBreadcrumbP: () => {},
    addMessageP: () => {},
    history: {},
    i18n: {},
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(AuctionFileUpdate)));
