// Essential for all components
import React, {Component, Fragment, createRef} from 'react';
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import { get, forEach, values, reduce, map, remove, isEqual } from 'lodash-es';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { getDatasource, defaultColumnDef, dateValueFormatter } from '../../utils/AgGridUtils';

import memoizeOne from 'memoize-one';
import {apiOrder} from "../../Api/_ApiOrder";
import {apiReport} from '../../Api/_ApiReport';
import {apiFactory} from '../../Api/_ApiFactory';
import {saveAs} from 'file-saver';
import Bluebird from 'bluebird';
import CommonUtils from '../../utils/CommonUtils';

const ID_FIELD_NAME = 'id';

const SAMPLE_DATA = [{
    id: '1',
    recyclable_name: '膠樽',
    processor_name: '劉財記',
    weight_record: 34,
    weight_checkout_record: 34,
    weight_receipt_record: null,
    receipts: [],
    lastmoddate: null,
}];

function getColumnDefs(language, component) {
    const columnDefs = [{
        field: 'recyclable_name',
        headerName: 'Recyclable',
        cellRenderer: function(params) {
            return <Link push to={'/' +
                encodeURIComponent(language) +
                '/recyclable/' +
                encodeURIComponent(get(params, ['data', ID_FIELD_NAME]))}
            >
                {params.value}
            </Link>;
        }
    }, {
        field: 'processor_name',
        headerName: 'Default Processor',
    }, {
    //     field: 'stores',
    //     headerName: 'Locations',
    // }, {
        field: 'lastmoddate',
        headerName: 'Last Updated',
        cellRenderer: dateValueFormatter
    }];
    return columnDefs;
}

function getRowId(params) {
    return get(params, ['data', ID_FIELD_NAME]);
}

class RecyclableManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowCount: 0,
            rowData: [],
            gridReady: false,
            reactReady: false,
            isUploading: false,
            order_weight_summary_id: null,
        };
        this.fileInput = createRef();
        this.handleGridReady = this.handleGridReady.bind(this);
        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
        this.handleAddClicked = this.handleAddClicked.bind(this);
        this.handleEditClicked = this.handleEditClicked.bind(this);
        this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
        this.fetchRowData = this.fetchRowData.bind(this);
        this.onFileSelected = this.onFileSelected.bind(this);
        this.getColumnDefs = memoizeOne(getColumnDefs);
    }

    handleGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        const { reactReady } = this.state;
        this.setState({ gridReady: true });
        if (reactReady) {
            this.fetchRowData();
        }
    }

    handleSelectionChanged(evt) {
        const selectedRowCount = evt.api.getSelectedNodes().length;
        this.setState({
            selectedRowCount
        });
    }

    handleAddClicked() {
        const { i18n, history } = this.props;
        history.push('/' + encodeURIComponent(i18n.language) + '/recyclable');
    }

    handleEditClicked() {
        if (!this.gridApi) {
            return;
        }
        const selectedRowNodes = this.gridApi.getSelectedNodes();
        if (!selectedRowNodes || selectedRowNodes.length !== 1) {
            return;
        }
        const id = selectedRowNodes[0].data[ID_FIELD_NAME];
        const { i18n } = this.props;
        this.props.history.push('/' + encodeURIComponent(i18n.language) + '/recyclable/' + encodeURIComponent(id));
    }

    handleDeleteClicked() {
        if (!this.gridApi) {
            return;
        }
        const selectedRowNodes = this.gridApi.getSelectedNodes();
        if (!selectedRowNodes || !selectedRowNodes.length) {
            return;
        }
        return Bluebird.map(selectedRowNodes, (selectedRowNode) => {
            const id = get(selectedRowNode, ['data', ID_FIELD_NAME]);
            if (id != null) {
                // return apiStore.deleteStore(id);
            }
        }, {
            concurrency: 4
        }).then(() => {
            this.setState({
                openSnackbar: true,
                messageSnackbar: 'Delete recyclable mapping(s) successfully',
                variantSnackbar: 'success'
            });
        }).catch(console.error);
    }

    handleCloseSnackbar() {
        this.setState({ openSnackbar: false });
    };

    fetchRowData() {
        if (this.gridApi) {
            this.gridApi.showLoadingOverlay();
        }

        return apiOrder.getFactoryOrderItemSubTypeList({
            // 'factory/tenant': localStorage.getItem('asTenantId'),
            $expand: 'order_item_sub_type,factory',
        }).then(obj => {
            if (obj.status == 200) {
                const rowData = map(obj.data, datum => {
                    return {
                        id: datum.factory_order_item_sub_type_id,
                        recyclable_name: datum.order_item_sub_type.label_zh,
                        processor_name: datum.factory.name,
                        lastmoddate: datum.lastmoddate,
                    };
                });
                this.setState({
                    rowData: rowData
                });
            }
        });
    }

    onFileSelected(evt) {
        const elem = get(this, ['fileInput', 'current']);
        if (elem) {
            this.setState({
                isUploading: true
            });
            const files = elem.files;
            if (files && files.length > 0) {
                let summaryId = this.state.order_weight_summary_id;
                if (!summaryId) {
                    const { upload_row_data } = this.state;
                    const factoryId = get(upload_row_data, 'factoryId');
                    if (!factoryId) {
                        return;
                    }
                    summaryId = apiReport.addOrderWeightSummary({
                        record_date: get(upload_row_data, 'date'),
                        weight: 0,
                        tenant: localStorage.getItem('asTenantId'),
                        order_item_sub_type: get(upload_row_data, 'typeId'),
                        factory: factoryId
                    }).then(res => {
                        if (res.status == 200 || res.status == 201) {
                            return res.data.order_weight_summary_id;
                        } else {
                            throw res;
                        }
                    });
                }
                const promise = Bluebird.resolve(summaryId).then((summaryId) => {
                    return Bluebird.map(files, file => {
                        return CommonUtils.readFile(file).then(base64Result => {
                            return apiReport.addOrderWeightSummaryFile({
                                order_weight_summary: summaryId,
                                file: {
                                    file_url: `Content-Type:${file.type || 'application/octet-stream'}\r\nContent-Disposition:attachment;filename="${encodeURI(file.name)}"\r\nContent-Transfer-Encoding: base64\r\n\r\n${base64Result.split('base64,')[1]}`,
                                }
                            });
                        })
                    }, {
                        concurrency: 1
                    });
                });
                promise.then(() => {
                    elem.value = '';
                    this.setState({
                        isUploading: false,
                        order_weight_summary_id: null
                    });
                    return this.fetchRowData();
                });
            }
        }
    }

    componentDidMount() {
        const { gridReady } = this.state;
        this.setState({ reactReady: true });
        if (gridReady) {
            this.fetchRowData();
        }
    }

    render() {
        const { i18n } = this.props;
        const { selectedRowCount, rowData, isUploading } = this.state;
        return <Container>
            <h2>Recyclable</h2>
            <div className="data-grid-menu">
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="primary"
                    onClick={this.handleAddClicked}
                >
                    添加
                </Button>
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="primary"
                    onClick={this.handleEditClicked}
                    disabled={selectedRowCount !== 1}
                >
                    編輯
                </Button>
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="secondary"
                    onClick={this.handleDeleteClicked}
                    disabled={selectedRowCount === 0}
                >
                    刪除
                </Button>
            </div>
            <AgGridReact
                className="data-grid ag-theme-alpine"
                onGridReady={this.handleGridReady}
                pagination="true"
                getRowId={getRowId}
                defaultColDef={defaultColumnDef}
                columnDefs={this.getColumnDefs(i18n.language, this)}
                rowData={rowData}
            ></AgGridReact>
            <input
                type="file"
                ref={this.fileInput}
                hidden
                multiple
                disabled={isUploading}
                onChange={this.onFileSelected}
            />
        </Container>;
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(RecyclableManagement)));