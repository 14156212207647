import * as BreadcrumbActionTypes from '../Constant/ActionType';

export const setBreadcrumb = (breadcrumbArr) => ({
    type: BreadcrumbActionTypes.SET_BREADCRUMB,
    breadcrumbArr,
});

export const addBreadcrumb = (breadcrumbItem) => ({
    type: BreadcrumbActionTypes.ADD_BREADCRUMB,
    breadcrumbItem,
});

export const getBreadcrumb = () => ({
    type: BreadcrumbActionTypes.GET_BREADCRUMB,
});