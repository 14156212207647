// Essential for all components
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
// Styling
import Grid from '@material-ui/core/Grid';
import {Button} from '@material-ui/core';
// Api
import {apiAuth} from '../../Api/ApiAuth';
// Redux
import {connect} from 'react-redux';
import {getUserInfo, login, logout, register} from '../../Redux/Action/authAction';
// Utils
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {get} from 'lodash-es';
// Children components
import ErrorMessage from '../../components/100Include/ErrorMessage';
import {setAccessToken, setForceLogout, setforceLogoutMsg} from "../../Api/_ApiFactoryWithHeader";
import {CacheService} from "../../utils/CacheService";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            MessageContent: ''
        }
    }

    // login
    _signInAsync = (values) => {
        const { t,i18n } = this.props;
        if (typeof (values) !== 'undefined') {
            let submitEmail = values.email;
            let submitPassword = values.password;

            apiAuth.authenticate(submitEmail, submitPassword).then((res) => {
                if(res && res.status === 200) {
                    let data = {
                        token: res.data.access_token,
                        refreshToken: res.data.refresh_token,
                    }
                    setAccessToken(res.data.access_token);
                    setForceLogout(()=>this.props.logoutP(data));
                    setforceLogoutMsg(t("LoginRegister:placeholder.forceLogout"));
                    this.props.loginP(data);
                    CacheService.saveAuthData(res.data);
                    this._getUserInformation(res.data.access_token, () => {
                        const tenantId = localStorage.getItem('asTenantId');
                        const endpoint = tenantId ? '/tenant-member-management' : '/user-management';
                        this.props.history.push('/' + i18n.language + endpoint);
                    });
                } else {
                    const error = res.data && res.data.error ? res.data.error : 'The credentials cannot be authenticated.';
                    this.setState({
                        MessageContent: error
                    });
                }
            }).catch((e) => {
                this.setState({
                    MessageContent: 'Something went wrong, please try again.'
                });
            });
        }
    }

    _getUserInformation = (access_token, successCallback) => {
        const cb = (obj) => {
            const body = obj.body;
            this.props.getUserInfoP(body);
            CacheService.saveProfileData(body);
            const tenant = get(body, ['tenant_members', 0, 'tenant']);
            const tenantId = get(tenant, 'tenant_id');
            const tenantTypeId = get(tenant, 'tenant_type');
            if (tenantId) {
                localStorage.setItem('asTenant', JSON.stringify(tenant));
                localStorage.setItem('asTenantId', tenantId);
                localStorage.setItem('asTenantTypeId', tenantTypeId);
            } else {
                localStorage.removeItem('asTenant');
                localStorage.removeItem('asTenantId');
                localStorage.removeItem('asTenantTypeId');
            }
            if (successCallback) {
                successCallback(obj);
            }
        }
        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }
        const params = {
            $expand: 'user_stores,tenant_members/tenant'
        };

        apiAuth.getUserInformation(params, access_token, cb, eCb);
    }


    // form login
    formLogin = ({ values, errors, touched, handleChange }) => {
        const { t } = this.props;
        return (
            <Grid item xm={12} md={6} xl={4} className="grid">
                <Form id="loginForm" className="form-wrapper login-form">
                    <Grid item xs={12} className="grid title">
                        {/* <p className="form-title">{t("LoginRegister:login.Title")}</p> */}
                        <img
                            style={{
                                textAlign: 'center',
                                width: '100%'
                            }}
                            src="/images/Logo.png"
                        />
                    </Grid>
                    <Grid container>
                        {this.state.MessageContent &&
                        <Grid item xs={12} className="ErrorMessage">
                            <ErrorMessage
                                message={this.state.MessageContent}
                            />
                        </Grid>
                        }
                        <Grid item xs={12} className="grid">
                            <Field name="email" type="text" placeholder={t("LoginRegister:placeholder.ID")} maxLength="100" style={{ 'width': '100%' }} />
                            {errors.email && touched.email ? <ErrorMessage message={errors.email} /> : null}
                        </Grid>
                        <Grid item xs={12} className="grid">
                            <Field name="password" type="password" placeholder={t("LoginRegister:placeholder.Password")} maxLength="100" style={{ 'width': '100%' }} />
                            {errors.password && touched.password ? <ErrorMessage message={errors.password} /> : null}
                        </Grid>
                        <Grid item xs={12} className="grid">
                            <Button type="submit" onClick={() => { this._signInAsync() }}>{t("LoginRegister:login.Login")}</Button>
                        </Grid>
                    </Grid>
                </Form>
            </Grid>
        )
    }

    render() {
        const { t } = this.props;

        const Schema = Yup.object().shape({
            email: Yup.string()
                .required(t("LoginRegister:login.Checking2"))
                .email(t("LoginRegister:login.Checking1")),
            password: Yup.string()
                .typeError(t("LoginRegister:login.Checking3"))
                .required(t("LoginRegister:login.Checking4")),
        })

        return (
            <div className="login-page">
                <div className="main__container flex-center-item">
                    <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    validationSchema={Schema}
                    onSubmit={this._signInAsync}
                    component={this.formLogin}
                />
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data)),
    logoutP: data => dispatch(logout(data)),
    getUserInfoP: data => dispatch(getUserInfo(data)),
    registerP: data => dispatch(register(data))
});


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Login)));
