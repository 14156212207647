// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {connect} from "react-redux";
import moment from "moment";
import ErrorMessage from "../../components/100Include/ErrorMessage";
import {addMessage} from "../../Redux/Action/messageAction";
import {apiClient} from "../../Api/_ApiClient";
import {formatCurrency} from "../../utils/Utility";

class AddCredit extends Component {

    state = {
        creditValue: null,
        creditCustomValue: '',
        clientDetail: null,
        error: null
    };

    componentDidMount() {
        this.getDetail();
    }

    getDetail = () => {
        const { t, i18n } = this.props;
        apiClient.getClientDetail(this.props.id, {}).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data) {
                    const client = obj.data;
                    const breadcrumbData = [
                        {
                            title: t('ClientManagement:breadcrumb'),
                            link: '/' + i18n.language + '/client-management'
                        },
                        {
                            title: client.display_name,
                            link: null
                        },
                        {
                            title: t('ClientManagement:addCredit'),
                            link: null
                        }
                    ];
                    this.props.setBreadcrumbP({ breadcrumbData: breadcrumbData });
                    this.setState( {
                        clientDetail: client
                    });
                }
            } else {
                console.log(obj.error)
            }
        })
    }

    back = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + `/client-management/${this.props.id}`);
    }

    handleChange = (event) => {
        this.setState({creditValue: event.target.value});
    };

    setCustomValue = (event) => {
        this.setState({creditCustomValue: event.target.value})
    };

    addCredit = () => {
        const { i18n } = this.props;
        if(this.state.creditValue && (this.state.creditValue !== 'other' || (/\d/.test(this.state.creditCustomValue)))) {
            if(this.state.creditValue === 'other' && !this.state.creditCustomValue) {
                this.setState({error: 'Please input custom credit value.'})
            } else {
                this.setState({error: null})
                // Call API
                const body = {
                    "client": this.props.id,
                    "description": "Admin Added",
                    "credit_number": this.state.creditValue !== 'other' ? this.state.creditValue : this.state.creditCustomValue
                };
                apiClient.addCredit(body).then(obj => {
                    if (obj.status === 201) {
                        const msgDsp = {
                            messageSnackbar: 'Add credit successfully',
                            variantSnackbar: 'success',
                            key: new Date().getTime(),
                        };
                        //Add msg
                        this.props.addMessageP(msgDsp);
                        this.props.history.push('/' + i18n.language + `/client-management/${this.props.id}`);
                    } else {
                        const error = obj.data && obj.data.error ? obj.data.error : 'Add credit failed';
                        //Add msg
                        this.props.addMessageP({
                            messageSnackbar: error,
                            variantSnackbar: 'error',
                            key: new Date().getTime(),
                        });
                    }
                })
            }
        } else {
            this.setState({error: 'Please input valid number credit value.'})
        }
    }

    render() {

        const { t } = this.props;
        const { creditValue, clientDetail, creditCustomValue, error } = this.state;

        return (
            clientDetail ? (
                <div>
                    <div className="main__container flex-center-item">
                        <div className="full-width client-detail">
                            <Grid container xm={12} alignItems="center">
                                <Grid item xs={2} className="form-item">
                                    {t("ClientManagement:userName")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {clientDetail.display_name}
                                </Grid>

                                <Grid item xs={2} className="form-item">
                                    {t("ClientManagement:wechat")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {clientDetail.wechat_id}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                    {t("ClientManagement:email")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {clientDetail.email}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                    {t("ClientManagement:active")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {clientDetail.active ? 'Yes' : 'No'}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                    {t("ClientManagement:creditBalance")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {formatCurrency(clientDetail.credit, '')}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                    {t("ClientManagement:maxCredit")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {formatCurrency(clientDetail.max_credit, '')}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                    {t("ClientManagement:updatedDate")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {moment(clientDetail.lastmoddate).format('YYYY-MM-DD')}
                                </Grid>
                                <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20" >
                                    <Grid item xs={12} className="form-item">
                                        Add Credit:
                                    </Grid>
                                    <Grid item xs={12} className="form-item">
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label="gender" name="gender1" value={creditValue} onChange={this.handleChange}>
                                                <FormControlLabel value="100000" control={<Radio />} label="$100.000" />
                                                <FormControlLabel value="200000" control={<Radio />} label="$200.000" />
                                                <FormControlLabel value="500000" control={<Radio />} label="$500.000" />
                                                <FormControlLabel value="1000000" control={<Radio />} label="$1.000.000" />
                                                <FormControlLabel value="other" control={<Radio />} label={<input type="text" value={creditCustomValue} onChange={this.setCustomValue}/>} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} className="form-item">
                                    {error && <ErrorMessage message={error} />}
                                </Grid>
                                <Grid item xs={12} className="button-wrapper form-item pt20">
                                    <Button type="button" className="primary-button" onClick={() => { this.addCredit() }}>Add</Button>
                                    <Button type="button" className="back-button" onClick={() => { this.back() }}>Back</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            ) : null
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(AddCredit)));
