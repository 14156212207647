// Essential for all components
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

// Api
import {logout} from "../../Redux/Action/authAction";

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';

import Breadcrumb from "./BreadCrumb";
import {clearLoginData} from "../../utils/AuthService";
import AppBar from "@material-ui/core/AppBar";

const drawerWidth = 260;
const styles = theme => ({
    appBar: {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        backgroundImage: 'linear-gradient(to right, white , white)',
        zIndex: 40,
    },
    root: {
        flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    typography: {
        flexGrow: 1,
        align: "center"
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
});

class PrivateHeader extends Component {
    state = {
        anchorEl: null,
        openMenu: false
    };

    handleToggle = () => {
        this.setState(state => ({ openMenu: !state.openMenu }));
    };

    handleClose = (event) => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ openMenu: false });
    };

    goToProfile = () => {
        const { i18n } = this.props;
        this.props.history.push(`/${i18n.language}/profile`);
    }

    logOut = () => {
        this.setState({ openMenu: false, anchorEl: null });
        clearLoginData().then(() => {
            this.props.history.push('');
        });
        this.props.logoutP();
    }

    render() {
        
        const { classes, t, i18n, auth } = this.props;
        const { openMenu } = this.state;
        const helpButton = (<Link to={"/" + i18n.language + '/'} className="color-white font-default">{t("Common:General.Title")}</Link>);
        const tenant = JSON.parse(localStorage.getItem('asTenant'));

        return (
            <AppBar position="fixed" className={classes.appBar} elevation={0}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={this.props.handleDrawerToggle}
                        className={this.props.btnMenuClass}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography color="inherit" className={classes.grow}>
                        {auth.auth ? (<Breadcrumb />) : ''}
                    </Typography>
                    {auth.auth ? (
                        <div className="account-info">
                            <span className="role">{function () {
                                if (tenant) {
                                    return tenant.name;
                                } else {
                                    return 'System Admin';
                                }
                                //auth.userInfo &&  auth.userInfo.role === 1 ? 'Admin': 'User'
                            }()}</span>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.logOut}
                            >登出</Button>
                        </div>
                    ): helpButton }
                </Toolbar>
            </AppBar>
        )
    }
}

PrivateHeader.propTypes = {
    siteType: PropTypes.object,
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    router: state.router,
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
    logoutP: data => dispatch(logout(data)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles, { withTheme: true })(PrivateHeader))));
