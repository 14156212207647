// @material-ui
import { createMuiTheme } from '@material-ui/core/styles';

import {
    SCREEN_SIZE_SM,
    SCREEN_SIZE_MD,
    SCREEN_SIZE_LG
} from '../../../config';

export const theme = createMuiTheme({
    // typography: {
    //     useNextVariants: true,
    // },
    breakpoints: {
        values: {
            xs: 0,
            sm: SCREEN_SIZE_SM,
            md: SCREEN_SIZE_MD,
            lg: SCREEN_SIZE_LG,
            xl: 1920
        }
    },
    // ripple: {
    //     color: 'red',
    // },
    palette: {
        primary: {
            main_feature: '#00793F',
            main: '#00793F',
            light: '#75AD66',
            dark: '#00793F',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#dc3545',
            light: '#ff6b70',
            dark: '#a3001e',
            contrastText: '#ffffff',
        },
    },
});