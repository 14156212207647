import * as AuthActionTypes from '../Constant/ActionType';

const initialState = {
	auth: false,
	token: null,
	refreshToken: null,
	userInfo: [],
	register: false,
	forgetPasswordBy: null
};

const authReducer = (state = initialState, action) => {

	switch (action.type) {
		// START FROM HERE
		case AuthActionTypes.LOGIN_SUCCESS: {
			return {
				...state,
				auth: true,
				token: action.data.token,
				refreshToken: action.data.refreshToken,
			}
		}

		case AuthActionTypes.REGISTER_SUCCESS: {
			return {
				...state,
				register: action.data
			}
		}

		case AuthActionTypes.GET_USER_INFO: {
			return {
				...state,
				userInfo: action.data
			}
		}

		case AuthActionTypes.REFRESH_TOKEN_BY_REFRESH_TOKEN: {
			return {
				...state,
				token: action.data.access_token,
				refreshToken: action.data.refresh_token
			}
		}

		case AuthActionTypes.LOGOUT: {
			return {
				auth: false,
				token: null,
				refreshToken: null,
				userInfo: [],
				register: false,
				forgetPasswordBy: null
			}
		}

		case AuthActionTypes.FORGET_PASSWORD: {
			return {
				...state,
				forgetPasswordBy: action.data
			}
		}

		case AuthActionTypes.FORGET_PASSWORD_CLEAR: {
			return {
				...state,
				forgetPasswordBy: null
			}
		}

		default:
			return state;
	}
};

export default authReducer;
