import * as MessageActionTypes from '../Constant/ActionType';
import {updateObject} from "../../utils/Utility";

const initialState = {
    msgArr: [],
};

const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case MessageActionTypes.SET_MESSAGE:
            return updateObject( state, { msgArr: action.messageArr.msgData } );
        case MessageActionTypes.ADD_MESSAGE:
            let currentArr = Array.from(state.msgArr || []);
            currentArr.push(action.messageItem);
            return updateObject( state, { msgArr: currentArr } );
        case MessageActionTypes.GET_MESSAGE:
            return state;
        default:
            return state;
    }
};

export default messageReducer;