import {api} from './_ApiFactoryWithHeader';

export const apiQrCodeGroup = {
    getList: (params, cancelToken) => api.get('qr_code_groups', params, null, cancelToken),

    getDetail: (id, params) => api.get(`qr_code_groups/${encodeURIComponent(id)}`, params, null, null),

    create: (body) => {
        return api.post('qr_code_groups', body);
    },

    update: (id, body) => {
        return api.put(`qr_code_groups/${id}`, body);
    },

    delete: (ids) => {
        return api.delete(`qr_code_groups?qr_code_group_id%5Bin%5D=${encodeURIComponent(ids)}`, null, null, null);
    },
};
