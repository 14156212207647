// Essential for all components
import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import { get, map } from 'lodash-es';
import { Button } from '@material-ui/core';
import { AgGridReact } from '@ag-grid-community/react';
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";
import {MySnackbarContentWrapper} from "../../components/102MaterialDesign/MySnackbarContentWrapper";
import Container from '@material-ui/core/Container';
import Snackbar from "@material-ui/core/Snackbar";
import { getDatasource, dateValueFormatter, defaultColumnDef } from '../../utils/AgGridUtils';

import {apiUsers} from "../../Api/_ApiUsers";

const ID_FIELD_NAME = 'username';

const columnDefCache = {};
function getColumnDefs(language) {
    let columnDefs = columnDefCache[language];
    if (!columnDefs) {
        columnDefs = [{
            field: 'display_name',
            headerName: '用戶顯示名稱',
            checkboxSelection: true,
            cellRenderer: function(params) {
                return <Link push to={'/' +
                    encodeURIComponent(language) +
                    '/user-management/' +
                    encodeURIComponent(get(params, ['data', ID_FIELD_NAME]))}
                >
                    {params.value}
                </Link>;
            }
        }, {
            field: 'phone',
            headerName: '電話'
        }, {
            field: 'email',
            headerName: '電子郵件'
        }, {
            field: 'lastmoddate',
            headerName: '最後更新',
            valueFormatter: dateValueFormatter
        }];
        columnDefCache[language] = columnDefs;
    }
    return columnDefs;
}

const DATASOURCE = getDatasource('users');

function getRowId(params) {
    return params.data[ID_FIELD_NAME];
}

class UserManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRowCount: 0,
        };
        this.handleGridReady = this.handleGridReady.bind(this);
        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
        this.handleAddClicked = this.handleAddClicked.bind(this);
        this.handleEditClicked = this.handleEditClicked.bind(this);
        this.handleDeleteClicked = this.handleDeleteClicked.bind(this);
    }

    handleGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    handleSelectionChanged(evt) {
        const selectedRowCount = evt.api.getSelectedNodes().length;
        this.setState({
            selectedRowCount
        });
    }

    handleAddClicked() {
        const { i18n } = this.props;
        this.props.history.push('/' + encodeURIComponent(i18n.language) + '/user');
    }

    handleEditClicked() {
        if (!this.gridApi) {
            return;
        }
        const selectedRowNodes = this.gridApi.getSelectedNodes();
        if (!selectedRowNodes || selectedRowNodes.length !== 1) {
            return;
        }
        const id = selectedRowNodes[0].data[ID_FIELD_NAME];
        const { i18n } = this.props;
        this.props.history.push('/' + encodeURIComponent(i18n.language) + '/user/' + encodeURIComponent(id));
    }

    handleDeleteClicked() {
        if (!this.gridApi) {
            return;
        }
        const selectedRowNodes = this.gridApi.getSelectedNodes();
        if (!selectedRowNodes || !selectedRowNodes.length) {
            return;
        }
        const ids = map(selectedRowNodes, (selectedRowNode) => {
            return selectedRowNode.data[ID_FIELD_NAME];
        });
        return apiUsers.deleteUser(ids).then(rs => {
            if (rs.status === 204) {
                this.setState({
                    openSnackbar: true,
                    messageSnackbar: 'Delete user(s) successfully',
                    variantSnackbar: 'success'
                });
                this.gridApi.setDatasource(DATASOURCE);
            } else {
                throw rs;
            }
        }).catch(console.error);
    }

    handleLogout() {
        this.props.logoutP();
    }

    handleCloseSnackbar() {
        this.setState({ openSnackbar: false });
    };

    render() {
        const { selectedRowCount, openSnackbar, variantSnackbar, messageSnackbar } = this.state;
        return <Container>
            <h2>用戶</h2>
            <div className="data-grid-menu">
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="primary"
                    onClick={this.handleAddClicked}
                >
                    添加
                </Button>
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="primary"
                    onClick={this.handleEditClicked}
                    disabled={selectedRowCount !== 1}
                >
                    編輯
                </Button>
                <Button
                    className="data-grid-menu-item"
                    variant="contained"
                    color="secondary"
                    onClick={this.handleDeleteClicked}
                    disabled={selectedRowCount === 0}
                >
                    刪除
                </Button>
            </div>
            <AgGridReact
                className="data-grid ag-theme-alpine"
                onGridReady={this.handleGridReady}
                pagination="true"
                rowSelection="multiple"
                onSelectionChanged={this.handleSelectionChanged}
                rowModelType="infinite"
                getRowId={getRowId}
                defaultColDef={defaultColumnDef}
                columnDefs={getColumnDefs(this.props.i18n.language)}
                datasource={DATASOURCE}
            ></AgGridReact>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={this.handleCloseSnackbar}
            >
                <MySnackbarContentWrapper
                    onClose={this.handleCloseSnackbar}
                    variant={variantSnackbar}
                    message={messageSnackbar}
                />
            </Snackbar>
        </Container>;
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(UserManagement)));