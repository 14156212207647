// export const WEBSITE_URL = 'http://localhost:8080';
// export const WEBSITE_URL = '';
export const WEBSITE_URL = 'https://astd.joyaether.com';
// export const WEBSITE_URL = 'http://ec2-18-167-121-104.ap-east-1.compute.amazonaws.com';
export const API_CENTRAL_URL_BASE = WEBSITE_URL + '/ofl-server';
export const API_CENTRAL_URL = API_CENTRAL_URL_BASE + '/';
export const GA_TRACKING_ID = 'UA-xxxxxxxx';

export const SCREEN_SIZE_SM = 768;
export const SCREEN_SIZE_MD = 992;
export const SCREEN_SIZE_LG = 1200;
