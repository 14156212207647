// Essential for all components
import React, {Component, Fragment} from 'react';
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import { get, isEqual } from 'lodash-es';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";
import Container from '@material-ui/core/Container';
import { getDatasource, defaultColumnDef } from '../../utils/AgGridUtils';

import memoizeOne from 'memoize-one';
import {apiStore} from "../../Api/_ApiStore";
import {apiReport} from '../../Api/_ApiReport';
import {saveAs} from 'file-saver';

const ID_FIELD_NAME = 'time';

function getColumnDefs(language, component) {
    const { isDownloading } = component.state;
    const columnDefs = [{
        field: 'time',
        headerName: 'Time',
        cellRenderer: function(params) {
            return <Link push to={'/' +
                encodeURIComponent(language) +
                '/report_1/' +
                get(params, ['data', 'time'], '')
            }
            >
                {params.value == null || params.value == '' ? '' : moment(params.value).utc().format('YYYY-MM')}
            </Link>;
        }
    }, {
        field: 'record_count',
        headerName: 'Records',
    }, {
        field: 'receipt_count',
        headerName: 'Receipts',
        cellRenderer: function(params) {
            return <Link push to={'/' +
                encodeURIComponent(language) +
                '/report_3/' +
                get(params, ['data', 'time'], '')
            }
            >
                {params.value || 0}
            </Link>;
        }
    }, {
        field: 'time',
        headerName: 'Download',
        wrapText: true,
        autoHeight: true,
        cellRenderer: function(params) {
            return <Fragment>
                <a
                    href="#"
                    className={isDownloading ? 'disabled' : ''}
                    onClick={(evt) => {
                        const value = params.value == null ? null : params.value;
                        evt.preventDefault();
                        component.setState({
                            isDownloading: true
                        });
                        component.gridApi.showLoadingOverlay();
                        apiReport.generateReport({
                            date: value,
                            tenant_id: localStorage.getItem('asTenantId')
                        }).then((response) => {
                            if (response.status === 200) {
                                let filename = 'report_' + moment(value).format('YYYY-MM') + '_generated_on_' + moment().format('YYYY-MM-DD_HH_mm_ss') + '.xlsx';
                                const headerLine = response.headers['Content-Disposition'];
                                if (headerLine) {
                                    const startFileNameIndex = headerLine.indexOf('"') + 1
                                    const endFileNameIndex = headerLine.lastIndexOf('"');
                                    filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
                                }
                                return saveAs(response.data, filename);
                            }
                        }).finally(() => {
                            component.setState({
                                isDownloading: false
                            });
                            component.gridApi.hideOverlay();
                        });
                    }}
                >
                    磅重報告
                </a>
            </Fragment>;
        }
    }, {
        field: 'time',
        headerName: 'Download',
        wrapText: true,
        autoHeight: true,
        cellRenderer: function(params) {
            return <Fragment>
                <a
                    href="#"
                    className={isDownloading ? 'disabled' : ''}
                    onClick={(evt) => {
                        const value = params.value == null ? null : params.value;
                        evt.preventDefault();
                        component.setState({
                            isDownloading: true
                        });
                        component.gridApi.showLoadingOverlay();
                        apiReport.generateCheckoutCombineReport({
                            date: value,
                            tenant_id: localStorage.getItem('asTenantId')
                        }).then((response) => {
                            if (response.status === 200) {
                                let filename = 'checkout_combine_report_' + moment(value).format('YYYY-MM') + '_generated_on_' + moment().format('YYYY-MM-DD_HH_mm_ss') + '.xlsx';
                                const headerLine = response.headers['Content-Disposition'];
                                if (headerLine) {
                                    const startFileNameIndex = headerLine.indexOf('"') + 1
                                    const endFileNameIndex = headerLine.lastIndexOf('"');
                                    filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
                                }
                                return saveAs(response.data, filename);
                            }
                        }).finally(() => {
                            component.setState({
                                isDownloading: false
                            });
                            component.gridApi.hideOverlay();
                        });
                    }}
                >
                    出貨報告
                </a>
            </Fragment>;
        }
    }, {
        field: 'time',
        headerName: 'Download',
        wrapText: true,
        autoHeight: true,
        cellRenderer: function(params) {
            return <Fragment>
                <a
                    href="#"
                    className={isDownloading ? 'disabled' : ''}
                    onClick={(evt) => {
                        const value = params.value == null ? null : params.value;
                        evt.preventDefault();
                        component.setState({
                            isDownloading: true
                        });
                        component.gridApi.showLoadingOverlay();
                        apiReport.generateAdjustedCombineReport({
                            date: value,
                            tenant_id: localStorage.getItem('asTenantId')
                        }).then((response) => {
                            if (response.status === 200) {
                                let filename = 'adjusted_combine_report_' + moment(value).format('YYYY-MM') + '_generated_on_' + moment().format('YYYY-MM-DD_HH_mm_ss') + '.xlsx';
                                const headerLine = response.headers['Content-Disposition'];
                                if (headerLine) {
                                    const startFileNameIndex = headerLine.indexOf('"') + 1
                                    const endFileNameIndex = headerLine.lastIndexOf('"');
                                    filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
                                }
                                return saveAs(response.data, filename);
                            }
                        }).finally(() => {
                            component.setState({
                                isDownloading: false
                            });
                            component.gridApi.hideOverlay();
                        });
                    }}
                >
                    調整報告
                </a>
            </Fragment>;
        }
    }, {
        field: 'time',
        headerName: 'Download',
        wrapText: true,
        autoHeight: true,
        cellRenderer: function(params) {
            return <Fragment>
                <a
                    href="#"
                    className={isDownloading ? 'disabled' : ''}
                    onClick={(evt) => {
                        const value = params.value == null ? null : params.value;
                        evt.preventDefault();
                        component.setState({
                            isDownloading: true
                        });
                        component.gridApi.showLoadingOverlay();
                        apiReport.generateReportImage({
                            date: value,
                            tenant_id: localStorage.getItem('asTenantId')
                        }).then((response) => {
                            if (response.status === 200) {
                                let filename = 'report_image_' + moment(value).format('YYYY-MM') + '_generated_on_' + moment().format('YYYY-MM-DD_HH_mm_ss') + '.docx';
                                const headerLine = response.headers['Content-Disposition'];
                                if (headerLine) {
                                    const startFileNameIndex = headerLine.indexOf('"') + 1
                                    const endFileNameIndex = headerLine.lastIndexOf('"');
                                    filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
                                }
                                return saveAs(response.data, filename);
                            }
                        }).finally(() => {
                            component.setState({
                                isDownloading: false
                            });
                            component.gridApi.hideOverlay();
                        });
                    }}
                >
                    相片記錄
                </a>
            </Fragment>;
        }
    }, {
        field: 'time',
        headerName: 'Download',
        wrapText: true,
        autoHeight: true,
        cellRenderer: function(params) {
            return <Fragment>
                <a
                    href="#"
                    className={isDownloading ? 'disabled' : ''}
                    onClick={(evt) => {
                        const value = params.value == null ? null : params.value;
                        evt.preventDefault();
                        component.setState({
                            isDownloading: true
                        });
                        component.gridApi.showLoadingOverlay();
                        apiReport.generateReportReceiptImage({
                            date: value,
                            tenant_id: localStorage.getItem('asTenantId')
                        }).then((response) => {
                            if (response.status === 200) {
                                let filename = 'report_receipt_image_' + moment(value).format('YYYY-MM') + '_generated_on_' + moment().format('YYYY-MM-DD_HH_mm_ss') + '.zip';
                                const headerLine = response.headers['Content-Disposition'];
                                if (headerLine) {
                                    const startFileNameIndex = headerLine.indexOf('"') + 1
                                    const endFileNameIndex = headerLine.lastIndexOf('"');
                                    filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
                                }
                                return saveAs(response.data, filename);
                            }
                        }).finally(() => {
                            component.setState({
                                isDownloading: false
                            });
                            component.gridApi.hideOverlay();
                        });
                    }}
                >
                    收據記錄
                </a>
            </Fragment>;
        }
    // }, {
    //     field: 'time',
    //     headerName: 'Download',
    //     wrapText: true,
    //     autoHeight: true,
    //     cellRenderer: function(params) {
    //         return <Fragment>
    //             <a
    //                 href="#"
    //                 className={isDownloading ? 'disabled' : ''}
    //                 onClick={(evt) => {
    //                     const value = params.value == null ? null : params.value;
    //                     evt.preventDefault();
    //                     component.setState({
    //                         isDownloading: true
    //                     });
    //                     component.gridApi.showLoadingOverlay();
    //                     apiReport.generateCheckoutReport({
    //                         date: value,
    //                         tenant_id: localStorage.getItem('asTenantId')
    //                     }).then((response) => {
    //                         if (response.status === 200) {
    //                             let filename = 'checkout_report_' + moment(value).format('YYYY-MM') + '_generated_on_' + moment().format('YYYY-MM-DD_HH_mm_ss') + '.xlsx';
    //                             const headerLine = response.headers['Content-Disposition'];
    //                             if (headerLine) {
    //                                 const startFileNameIndex = headerLine.indexOf('"') + 1
    //                                 const endFileNameIndex = headerLine.lastIndexOf('"');
    //                                 filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
    //                             }
    //                             return saveAs(response.data, filename);
    //                         }
    //                     }).finally(() => {
    //                         component.setState({
    //                             isDownloading: false
    //                         });
    //                         component.gridApi.hideOverlay();
    //                     });
    //                 }}
    //             >
    //                 Checkout Report
    //             </a>
    //         </Fragment>;
    //     }
    // }, {
    //     field: 'time',
    //     headerName: 'Download',
    //     wrapText: true,
    //     autoHeight: true,
    //     cellRenderer: function(params) {
    //         return <Fragment>
    //             <a
    //                 href="#"
    //                 className={isDownloading ? 'disabled' : ''}
    //                 onClick={(evt) => {
    //                     const value = params.value == null ? null : params.value;
    //                     evt.preventDefault();
    //                     component.setState({
    //                         isDownloading: true
    //                     });
    //                     component.gridApi.showLoadingOverlay();
    //                     apiReport.generateAdjustedReport({
    //                         date: value,
    //                         tenant_id: localStorage.getItem('asTenantId')
    //                     }).then((response) => {
    //                         if (response.status === 200) {
    //                             let filename = 'adjusted_report_' + moment(value).format('YYYY-MM') + '_generated_on_' + moment().format('YYYY-MM-DD_HH_mm_ss') + '.xlsx';
    //                             const headerLine = response.headers['Content-Disposition'];
    //                             if (headerLine) {
    //                                 const startFileNameIndex = headerLine.indexOf('"') + 1
    //                                 const endFileNameIndex = headerLine.lastIndexOf('"');
    //                                 filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
    //                             }
    //                             return saveAs(response.data, filename);
    //                         }
    //                     }).finally(() => {
    //                         component.setState({
    //                             isDownloading: false
    //                         });
    //                         component.gridApi.hideOverlay();
    //                     });
    //                 }}
    //             >
    //                 Adjusted Report
    //             </a>
    //         </Fragment>;
    //     }
    }];
    return columnDefs;
}

// const REPORT_TYPE_NUMBER_MAP = {
//     '15e32046-7f60-444b-9c1f-fd3b1d8a36be': 1,
//     '665aadff-018e-432d-8c6c-e5ae935fbd41': 2,
//     'a4e7158c-2340-4012-81ca-d7be4234ddc8': 3,
//     'a9b00935-ba0d-480c-81be-4911112a43a1': 4,
//     'cd01c5b4-bb6e-44e6-85d5-e33db9dabb9b': 5
// }
// function getReportNumber(reportTypeId) {
//     return REPORT_TYPE_NUMBER_MAP[reportTypeId];
// }
function getDataSource(reportTypeId) {
    let datasource;
    // if (REPORT_TYPE_NUMBER_MAP.hasOwnProperty(reportTypeId)) {
    //     const reportTypeNumber = getReportNumber(reportTypeId);
    //     datasource = getDatasource(`report_${reportTypeNumber}_date_ranges`);
    // }
    datasource = getDatasource('report_date_ranges');
    return datasource;
}

function getRowId(params) {
    return get(params, ['data', ID_FIELD_NAME]);
}

class ReportDateManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDownloading: false
        };
        this.handleGridReady = this.handleGridReady.bind(this);
        this.getColumnDefs = memoizeOne(getColumnDefs, isEqual);
        this.getDataSource = memoizeOne(getDataSource, isEqual);
    }

    handleGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    render() {
        const { i18n } = this.props;
        return <Container>
            <h2>Report</h2>
            <AgGridReact
                className="data-grid ag-theme-alpine"
                onGridReady={this.handleGridReady}
                pagination="true"
                rowModelType="infinite"
                getRowId={getRowId}
                defaultColDef={defaultColumnDef}
                columnDefs={this.getColumnDefs(i18n.language, this)}
                datasource={this.getDataSource()}
                overlayLoadingTemplate='<span class="ag-overlay-loading-center">Generating report (this can takes several minutes)...</span>'
            ></AgGridReact>
        </Container>;
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ReportDateManagement)));