// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";

import moment from "moment";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {connect} from "react-redux";
import {apiUsers} from "../../Api/_ApiUsers";
import {isAdmin} from "../../utils/Utility";

class OrderDetails extends Component {

    state = {
        user: null
    };

    constructor(props) {
        super(props);
        this.getOrderDetail = this.getOrderDetail.bind(this);
    }

    componentDidMount() {
        this.getOrderDetail();
    }

    getOrderDetail() {
        const { i18n } = this.props;
        let params = {
            username: this.props.id,
            $expand: "access_rights,profile_image"
        }
        apiUsers.getUserList(params).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data && obj.data[0]) {
                    const userDetail = obj.data[0];
                    this.setBreadcrumbWithUserNumber({title:userDetail.display_name, link: null })
                    this.setState( {
                        user: this.prepareData(userDetail)
                    });
                }
            } else {
                this.props.history.push('/' + i18n.language + '/login');
            }
        })
    }

    setBreadcrumbWithUserNumber = (userInfoBreadCrumb) => {

        const { t, i18n } = this.props;
        const params= {
            '$select' : 'display_name',
            '$top' : 1
        };
        apiUsers.getUserList(params).then( (rs) => {
            if(rs && rs.status === 200) {
                const dataSum = rs.headers['content-range'].split('/')[1];

                this.props.setBreadcrumbP({breadcrumbData: [{title: t('UserManagement:breadcrumb') + ' (' + dataSum + ')',link: '/' + i18n.language + '/user-management'}, userInfoBreadCrumb]});

            } else {
                console.log("eCb : ", rs);
            }
        })
    }

    back = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/user-management?previous=true');
    }

    goToEditPage = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/user/'+this.state.user.username);
    }

    prepareData = data => {
        const item = {...data};
        item.update_date = item.lastmoddate ? moment(item.lastmoddate).format('YYYY-MM-DD') : '';
        return item;
    };

    render() {

        const { auth, t } = this.props;
        const { user } = this.state;

        return (
            user && (
                <div>
                    <div className="main__container flex-center-item">
                        <div className="full-width">
                            <Grid container xm={12} alignItems="center">
                                {isAdmin(auth.userInfo ? auth.userInfo.role : null) ?
                                    <Grid item xs={12} className="button-wrapper form-item pt20">
                                        <Button type="button" className="primary-button" onClick={() => { this.goToEditPage() }}>{t("UserManagement:button.edit")}</Button>
                                    </Grid>
                                : null}
                                <Grid item xs={2} className="form-item">
                                    {t("UserManagement:userName")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {user.display_name}
                                </Grid>

                                <Grid item xs={2} className="form-item">
                                    {t("UserManagement:email")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {user.email}
                                </Grid>

                                <Grid item xs={2} className="form-item">
                                    {t("UserManagement:role")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {user.role_in_string}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                    {t("UserManagement:passportPhoto")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {user.profile_image ? 'Uploaded' : 'Not uploaded'}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                    {t("UserManagement:updatedDate")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {user.update_date}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                </Grid>
                                <Grid item xs={12} className="button-wrapper form-item pt20">
                                    <Button type="button" className="primary-button" onClick={() => { this.back() }}>Back</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderDetails)));