import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import withStyles from '@material-ui/styles/withStyles';
import moment from "moment";

const styles = theme => ({
    calendar: {
        lineHeight: 'initial',
        '& .react-datepicker__header__dropdown': {
            marginTop: 4,
            marginBottom: 4,
        },
        '& .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow': {
            top: 4,
        }
    },
});

class CustomInput extends React.Component { // must be class components for giving ref
    render = () => (
        <input {...this.props} type="text" readOnly />
    )
}

export const DatePickerField = ({ classes, ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const formatString = props.showTimeSelect ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
    return (
        <DatePicker
            {...field}
            {...props}
            selected={(field.value && new Date(field.value)) || null}
            showYearDropdown
            showMonthDropdown
            calendarClassName={classes.calendar}
            customInput={<CustomInput />}
            onChange={val => {
                setFieldValue(field.name, moment(new Date(val)).format(formatString));
            }}
        />
    );
};

export default withStyles(styles)(DatePickerField);
