import { api } from "./_ApiFactoryWithHeader";

export const apiFile = {
  getFiles: (params) => {
    return api.get('files', params);
  },
  getFile: (id, params) => {
    return api.get(`files/${id}`, params);
  },
  updateFile: (id, body) => {
    return api.put(`files/${id}`, body);
  },
  createFile: (body) => {
    return api.post(`files`, body);
  },
  deleteFile: (id, params) => {
    return api.delete(`files/${id}`, params);
  },
  deleteFiles: (ids, params) => {
    return api.delete(`files?file_id%5Bin%5D=${encodeURIComponent(ids)}`, params);
  },
};
