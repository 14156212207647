// Essential for all components
import React, {Component, Fragment} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {
    ListItemText,
    Button,
    FormControl,
    Select,
    MenuItem
} from "@material-ui/core";
import {Field, FieldArray, Form, Formik, ErrorMessage} from "formik";
import moment from "moment";
import Bluebird from 'bluebird';
import * as Yup from "yup";
import {apiStore} from "../../Api/_ApiStore";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {connect} from "react-redux";
import {addMessage} from "../../Redux/Action/messageAction";
import DatePickerField from "../../components/103FormikCustom/DatePickerField";
import { get, map, some, sortBy, forEach, isString } from 'lodash-es';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

class StoreDetail extends Component {

    state = {
        store: {
            store_id: null,
            name: '',
            store_type: '',
            location: '',
            address: '',
            is_warehouse: null,
            warehousable: null,
            warehouse_checkoutable: null,
            lat: null,
            long: null,
            permanent: null,
            active: null,
            start_time: null,
            end_time: null,
            lastmoddate: null,
            store_time_slots: [],
        },
        removed_store_time_slot_ids: [],
        storeTypes: [],
        defaultValueError: null,
        openSnackbar: false,
        messageSnackbar: '',
        variantSnackbar: '',
    };

    componentDidMount() {
        this.getStoreTypeList();
        if (this.props.id) {
            this.getStoreDetail();
        } else {
            const { t } = this.props;
            const storeItemBreadcrumb = [{
                title: 'Add Store',
                link: null
            }];
            this.props.setBreadcrumbP({ breadcrumbData: storeItemBreadcrumb });
        }
    }

    getStoreTypeList = () => {
        apiStore.getStoreTypeList().then(obj => {
            if (obj && !obj.error) {
                if (obj.data) {
                    const optionData = obj.data;
                    this.setState({
                        storeTypes: optionData
                    });
                }
            } else {
                this.props.history.push('/' + this.props.i18n.language + '/login');
            }
        });
    }

    getStoreDetail = () => {
        if (this.props.id) {
            apiStore.getStoreDetail(this.props.id, {
                $expand: 'store_time_slots'
            }).then(obj => {
                if (obj && obj.status === 200 && obj.data) {
                    const store = obj.data;
                    this.props.setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: 'Store',
                                    link: null
                                },
                                {
                                    title: `Edit [${store.name}]`,
                                    link: null
                                }
                            ]
                        }
                    );

                    if (store.start_time != null) {
                        const startTimeStr = moment(store.start_time).format(DATETIME_FORMAT);
                        store.start_time = startTimeStr;
                    }
                    if (store.end_time != null) {
                        const endTimeStr = moment(store.end_time).format(DATETIME_FORMAT);
                        store.end_time = endTimeStr;
                    }
                    let store_time_slots = get(store, 'store_time_slots');
                    if (store_time_slots && store_time_slots.length > 0) {
                        store_time_slots = map(sortBy(store_time_slots, ['start_time', 'end_time']), storeTimeSlot => {
                            if (storeTimeSlot.start_time != null) {
                                const startTimeStr = moment(storeTimeSlot.start_time).format(DATETIME_FORMAT);
                                storeTimeSlot.start_time = startTimeStr;
                            }
                            if (storeTimeSlot.end_time != null) {
                                const endTimeStr = moment(storeTimeSlot.end_time).format(DATETIME_FORMAT);
                                storeTimeSlot.end_time = endTimeStr;
                            }
                            return storeTimeSlot;
                        });
                        store.store_time_slots = store_time_slots;
                    }
                    this.setState({
                        store: store
                    })
                } else {
                    console.log("eCb : ", obj);
                }
            })
        } else {
            this.props.setBreadcrumbP(null);
        }
    }

    _updateStoreAsync = (value) => {
        const {id} = this.props;
        const {removed_store_time_slot_ids} = this.state;
        let startTimestamp = value.start_time;
        if (isString(startTimestamp)) {
            startTimestamp = moment(startTimestamp , DATETIME_FORMAT).valueOf();
        }
        let endTimestamp = value.end_time;
        if (isString(endTimestamp)) {
            endTimestamp = moment(endTimestamp , DATETIME_FORMAT).valueOf();
        }
        const body = {
            tenant: localStorage.getItem('asTenantId'),
            name: value.name,
            store_type: value.store_type,
            location: value.location,
            address: value.address,
            is_warehouse: value.is_warehouse,
            warehousable: value.warehousable,
            warehouse_checkoutable: value.warehouse_checkoutable,
            lat: value.lat,
            long: value.long,
            permanent: !!value.permanent,
            active: !!value.active,
            start_time: startTimestamp,
            end_time: endTimestamp,
        };
        const store_time_slots = map(value.store_time_slots, storeTimeSlot => {
            let startTimestamp = storeTimeSlot.start_time;
            if (isString(startTimestamp)) {
                startTimestamp = moment(startTimestamp , DATETIME_FORMAT).valueOf();
            }
            let endTimestamp = storeTimeSlot.end_time;
            if (isString(endTimestamp)) {
                endTimestamp = moment(endTimestamp , DATETIME_FORMAT).valueOf();
            }
            const returnObject = {
                ...storeTimeSlot,
                start_time: startTimestamp,
                end_time: endTimestamp,
            };
            delete returnObject.tmp_id;
            return returnObject;
        });

        let updatePromise;
        if (id) {
            updatePromise = apiStore.updateStore(this.props.id, body);
            updatePromise = updatePromise.then((obj) => {
                if (obj.status !== 200 && obj.status !== 201) {
                    const msg = obj && obj.data.error ? obj.data.error : 'Cannot ' + (id ? 'update' : 'add') + ' store successfully';
                    const msgDsp = {
                        messageSnackbar: msg,
                        variantSnackbar: 'error',
                        key: new Date().getTime(),
                    };
                    this.props.addMessageP(msgDsp);
                    return obj;
                } else {
                    const storeTimeSlotItems = [];
                    const storeTimeSlotActions = [];
                    if (removed_store_time_slot_ids.length > 0) {
                        Array.prototype.push.apply(storeTimeSlotItems, removed_store_time_slot_ids);
                        const deleteStoreTimeSlot = (storeTimeSlotId) => {
                            return apiStore.deleteStoreTimeSlot(storeTimeSlotId);
                        };
                        for (let i = 0; i < removed_store_time_slot_ids.length; ++i) {
                            storeTimeSlotActions.push(deleteStoreTimeSlot);
                        }
                    }
                    if (store_time_slots.length > 0) {
                        forEach(store_time_slots, storeTimeSlot => {
                            storeTimeSlot.store = id;
                        })
                        Array.prototype.push.apply(storeTimeSlotItems, store_time_slots);
                        const createOrUpdate = (storeTimeSlot) => {
                            const id = get(storeTimeSlot, 'store_time_slot_id');
                            if (id) {
                                return apiStore.updateStoreTimeSlot(id, storeTimeSlot);
                            } else {
                                return apiStore.addStoreTimeSlot(storeTimeSlot);
                            }
                        };
                        for (let i = 0; i < store_time_slots.length; ++i) {
                            storeTimeSlotActions.push(createOrUpdate);
                        }
                    }
                    if (storeTimeSlotItems.length > 0) {
                        return Bluebird.map(storeTimeSlotItems, (storeTimeSlotItem, index) => {
                            const action = storeTimeSlotActions[index];
                            return action(storeTimeSlotItem);
                        }, {
                            concurrency: 4
                        }).then(results => {
                            let failedRequestResult;
                            const hasFailedRequest = some(results, result => {
                                const isFailedStatus = result.status >= 400;
                                if (isFailedStatus) {
                                    failedRequestResult = result;
                                }
                                return isFailedStatus;
                            });
                            if (hasFailedRequest) {
                                return failedRequestResult;
                            } else {
                                return obj;
                            }
                        });
                    } else {
                        return obj;
                    }
                }
            })
        } else {
            body.store_time_slots = store_time_slots;
            updatePromise = apiStore.addStore(body);
        }

        updatePromise.then(obj => {
            if (obj.status === 200 || obj.status === 201) {
                const msgDsp = {
                    messageSnackbar: (id ? 'Update' : 'Add') + ' store successfully.',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
                const {i18n} = this.props;
                const redirectUrl = '/' + i18n.language + '/store-management/' + (id || '');
                this.props.history.push(redirectUrl);
            } else {
                let msg = obj && obj.data.error ? obj.data.error : 'Cannot ' + (id ? 'update' : 'add') + ' store successfully';

                // handle special error case
                if (/foreign key constraint fails/.test(msg) && /FK_order_check_out_stores_store_time_slot_id/.test(msg)) {
                    msg = 'This time slot cannot be deleted because it contains order(s).';
                }

                const msgDsp = {
                    messageSnackbar: msg,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
            }
        });
    }

    cancel = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/store-management?previous=true');
    }

    formConfiguration = ({values, errors, touched, handleChange}) => {
        const {i18n} = this.props;
        const {storeTypes, removed_store_time_slot_ids} = this.state;
        return values && (
            <Form id="configurationForm" className=" full-width">
                <Grid container xm={12} alignItems="center" classes={{item: 'form-item'}}>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            收集點名稱
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="name" type="text" placeholder="" maxLength="191" />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="name" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" className="pt20">
                        <Grid item xs={2} className="form-item">
                            類型
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="store_type">
                                    {({ field, form, meta }) => (
                                        <FormControl className='full-width'>
                                            <Select
                                                required
                                                classes={{
                                                    root: 'custom-select'
                                                }}
                                                disableUnderline={true}
                                                MenuProps={MenuProps}
                                                {...field}
                                                onChange={field.onChange}
                                            >
                                                {storeTypes.map(item => {
                                                    const value = item.store_type_id;
                                                    const name = i18n.language && i18n.language.startsWith('zh') ? item.label_zh : item.label_en;
                                                    return <MenuItem key={value} value={value}>
                                                        <ListItemText primary={name} />
                                                    </MenuItem>;
                                                })}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="store_type" />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            Location
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="location" type="text" placeholder="" maxLength="191" />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="location" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            地址
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="address" type="text" placeholder="" />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="address" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            <label htmlFor="is_warehouse_checkbox">Is warehouse</label>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field id="is_warehouse_checkbox" type="checkbox" name="is_warehouse" />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="is_warehouse" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            <label htmlFor="warehousable_checkbox">Warehousable</label>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field id="warehousable_checkbox" type="checkbox" name="warehousable" />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="warehousable" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            <label htmlFor="warehouse_checkoutable_checkbox">Warehouse checkoutable</label>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field id="warehouse_checkoutable_checkbox" type="checkbox" name="warehouse_checkoutable" />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="warehouse_checkoutable" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            Latitude
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="lat" type="number" placeholder="" />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="lat" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            Longitude
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="long" type="number" placeholder="" />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="long" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            <label htmlFor="permanent_checkbox">長期?</label>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field id="permanent_checkbox" type="checkbox" name="permanent" />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="permanent" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            <label htmlFor="active_checkbox">狀態</label>
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field id="active_checkbox" type="checkbox" name="active" />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="active" />
                            </Grid>
                        </Grid>
                    </Grid>
                    {values.store_type == '3c2a62c0-b8f3-4d5d-8a8f-739c06d8f27f' && <FieldArray
                        name="store_time_slots"
                        render={arrayHelpers => {
                            return <Fragment>
                                <Grid container direction="row" justify="center" alignItems="flex-start" >
                                    <Grid item xs={2} className="form-item">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                return arrayHelpers.push({
                                                    tmp_id: Math.random(),
                                                    start_time: null,
                                                    end_time: null,
                                                });
                                            }}
                                        >
                                            Add time slot
                                        </Button>
                                    </Grid>
                                </Grid>
                                
                                {map(get(values, 'store_time_slots'), (storeTimeSlot, index) => {
                                    const store_time_slot_id = get(storeTimeSlot, 'store_time_slot_id');
                                    return <Grid container direction="row" justify="center" alignItems="flex-start" key={store_time_slot_id || get(storeTimeSlot, 'tmp_id') || index}>
                                        <Grid item xs={2} className="form-item">
                                            {index + 1}. 開始時間
                                        </Grid>
                                        <Grid item xs={3} className="form-item">
                                            <Grid item xs={12}>
                                                <Field name={`store_time_slots[${index}].start_time`}>
                                                    {({ field, form, meta }) => (
                                                        <DatePickerField
                                                            showTimeSelect
                                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                                            maxDate={storeTimeSlot.end_time == null ? null : storeTimeSlot.end_time}
                                                            {...field}
                                                            onChange={(evt) => {
                                                                console.log(evt);
                                                                field.onChange(evt);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ErrorMessage name={`store_time_slots[${index}].start_time`} />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} className="form-item">
                                            結束時間
                                        </Grid>
                                        <Grid item xs={3} className="form-item">
                                            <Grid item xs={12}>
                                                <Field name={`store_time_slots[${index}].end_time`}>
                                                    {({ field, form, meta }) => (
                                                        <DatePickerField
                                                            showTimeSelect
                                                            dateFormat="yyyy-MM-dd HH:mm:ss"
                                                            minDate={storeTimeSlot.start_time == null ? null : storeTimeSlot.start_time}
                                                            {...field}
                                                            onChange={(evt) => {
                                                                console.log(evt);
                                                                field.onChange(evt);
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ErrorMessage name={`store_time_slots[${index}].end_time`} />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} className="form-item">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => {
                                                    if (store_time_slot_id) {
                                                        removed_store_time_slot_ids.push(store_time_slot_id);
                                                    }
                                                    arrayHelpers.remove(index);
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        </Grid>
                                    </Grid>;
                                })}
                            </Fragment>;
                        }}
                    />}

                    {/* <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            開始時間
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="start_time">
                                    {({ field, form, meta }) => (
                                        <DatePickerField
                                            showTimeSelect
                                            {...field}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="start_time" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                        <Grid item xs={2} className="form-item">
                            結束時間
                        </Grid>
                        <Grid item xs={10} className="form-item">
                            <Grid item xs={12}>
                                <Field name="end_time">
                                    {({ field, form, meta }) => (
                                        <DatePickerField
                                            showTimeSelect
                                            {...field}
                                        />
                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorMessage name="end_time" />
                            </Grid>
                        </Grid>
                    </Grid> */}

                    <Grid item xs={2} className="form-item pt20">
                        Update Date
                    </Grid>
                    <Grid item xs={10} className="form-item pt20">
                        {values.lastmoddate ? moment(values.lastmoddate).format('YYYY-MM-DD') : ''}
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">Save</Button>
                        <Button type="button" className="second-button" onClick={() => {
                            this.cancel()
                        }}>Cancel</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const {store} = this.state;
        const Schema = Yup.object().shape({
            name: Yup.string().required("Name is required"),
            store_type: Yup.string().required("Store type is required"),
            location: Yup.string().nullable(),
            address: Yup.string().required("Address is required"),
            is_warehouse: Yup.boolean().nullable(),
            warehousable: Yup.boolean().nullable(),
            warehouse_checkoutable: Yup.boolean().nullable(),
            lat: Yup.number().nullable(),
            long: Yup.number().nullable(),
            permanent: Yup.boolean().nullable(),
            active: Yup.boolean().nullable(),

            store_time_slots: Yup.array().of(
                Yup.object().shape({
                    start_time: Yup.mixed().required("Start time is required"),
                    end_time: Yup.mixed().required("End time is required").when('start_time', (start_time, schema) => {
                        if (start_time == null) {
                            return schema;
                        }
                        return schema.test('is_end_time_later',
                            'End time must be later than start time',
                            (end_time, testContext) => {
                                return moment(end_time).isAfter(start_time);
                            }
                        );
                    })
                })
            )
            
        });

        return (
            <div>
                <div className="main__container flex-center-item">
                    <Formik
                        enableReinitialize
                        initialValues={store}
                        validationSchema={Schema}
                        onSubmit={this._updateStoreAsync}
                        component={this.formConfiguration}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(StoreDetail)));
