// Essential for all components
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

import BaseListPage from "../../components/101BaseListPage/BaseListPage";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";
import moment from "moment";
import {
    getCurrentStateOfPage,
    getPreviousPage,
    getValAfterSlash,
    handleSpecialFieldName
} from "../../utils/PaginationUtils";
import queryString from 'query-string'
import {COMMON_DATE_FORMAT} from "../../constants/common";
import {apiMessage} from "../../Api/_ApiMessage";
import {getDayOfWeekSelectedDisplay} from "../../utils/Utility";
import {addMessage} from "../../Redux/Action/messageAction";

// Define column names
const rows = [
    {
        id: 'title',
        numeric: false,
        disablePadding: false,
        label: 'Push Msg',
        link: '/push-msg-schedule/:push_notification_template_id',
        customUrlParam: ['push_notification_template_id']
    },
    {id: 'repeated', numeric: false, disablePadding: false, label: 'Repeated', disableSorting: true},
    {id: 'fix_schedule_send_date', numeric: false, disablePadding: false, label: 'Scheduled Send Date'},
    {id: 'lastmoddate', numeric: false, disablePadding: false, label: 'Update Date', type: 'date'}
];

const rowsPerPageDefault = 10;
const orderByDefault = 'lastmoddate';
const orderDefault = 'desc';

class PushMsgSchedule extends Component {

    state = {
        data: null,
        //pagination
        amountOfData: '',
        currentPage: 0,
        rowsPerPage: rowsPerPageDefault,
        orderBy: orderByDefault,
        order: orderDefault
    };


    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        let previousPage = values.previous ? getPreviousPage(this.props.currentPaginator, 'push-msg-schedule') : null;
        const {currentPage, rowsPerPage, orderBy, order} = getCurrentStateOfPage(previousPage, rowsPerPageDefault, orderByDefault, orderDefault)
        this.setState({
            currentPage: currentPage,
            rowsPerPage: rowsPerPage,
            orderBy: orderBy,
            order: order
        }, () => {
            this._getDataDynamic(currentPage * rowsPerPage, rowsPerPage, orderBy, order, true);
        })
    }

    _getDataDynamic = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        const {t, i18n} = this.props;
        orderBy = handleSpecialFieldName(orderBy);

        const params =
            {
                'send_status[ne]': 'completed',
                '$orderby': `${orderBy} ${order}`,
                '$top': rowsPerPage,
                '$skip': skipAmount,
            };

        apiMessage.getPushNotificationTemplates(params).then((rs) => {
            if (rs && rs.status === 200) {
                if (isResetNavAndDataAmount) {
                    const dataSum = getValAfterSlash(rs.headers['content-range']);
                    this.setState({amountOfData: dataSum});
                    this.props.setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: t('ClientManagement:sendPushMsg.breadcrumb') + ' (' + dataSum + ')',
                                    link: '/' + i18n.language + '/push-msg-schedule'
                                }
                            ]
                        }
                    );
                }
                this.setState({
                    data: this.prepareData(rs.data),
                });
            } else {
                console.log("eCb : ", rs);
            }
        });
    }

    prepareData = data => {
        return (data && data.length > 0) ? (
            data.map(item => {
                const repeatWeekDay = item.repeat_send_week_day ? item.repeat_send_week_day.split(',') : [];
                const dofDisplay = getDayOfWeekSelectedDisplay(repeatWeekDay);
                const timeDisplay = moment(item.repeat_send_hour, 'HH:mm').format('HH:mm a');
                return {
                    ...item,
                    id: item.push_notification_template_id,
                    update_date: moment(item.lastmoddate).format(COMMON_DATE_FORMAT),
                    ...(item.send_type === 'schedule' ? {
                        repeated: 'Nil',
                        fix_schedule_send_date: item.fix_schedule_send_date ? moment(item.fix_schedule_send_date).format('YYYY-MM-DD, HH:mm a') : 'Nil',
                    } : {
                        repeated: `Every ${dofDisplay}, ${timeDisplay}`,
                        fix_schedule_send_date: 'Nil',
                    }),
                };
            })
        ) : [];
    };

    _updateButtonAction = (id) => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/client/' + id);
    }

    _deleteButtonAction = (ids) => {
        console.log('_deleteButtonAction', ids)
        if (ids && ids.length > 0) {
            apiMessage.deletePushNotificationTemplates(ids, {}).then(rs => {
                if (rs.status === 204) {
                    const msgDsp = {
                        messageSnackbar: 'Delete Push Notification Templates successfully',
                        variantSnackbar: 'success',
                        key: new Date().getTime(),
                    };
                    this.props.addMessageP(msgDsp);
                    this._getDataDynamic(0, this.state.rowsPerPage, this.state.orderBy, this.state.order, true, null);
                } else {
                    console.log("eCb : ", rs);
                }
            });
        }
    }

    render() {
        const {data, amountOfData, currentPage, rowsPerPage, orderBy, order} = this.state;
        return (
            data &&
            <div>
                <BaseListPage
                    pageId={'push_notification-management'}
                    mainClass={'push_notification-mgt-page'}
                    primaryKey={'push_notification_template_id'}
                    displaySearchBar={false}
                    displayActionBar={true}

                    createButton={false}

                    updateButton={true}
                    updateButtonText="Edit"
                    updateButtonAction={this._updateButtonAction}

                    deleteButton={true}
                    deleteButtonText="Delete"
                    deleteButtonAction={this._deleteButtonAction}

                    getDataDynamic={this._getDataDynamic}
                    currentPage={currentPage}
                    rows={rows}
                    data={data}
                    displaySelectBox={true}
                    orderDefault={order}
                    orderByDefault={orderBy}
                    rowsPerPageDefault={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20]}
                    amountOfData={amountOfData}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(PushMsgSchedule)));
