// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";

import moment from "moment";
import {get, map, property} from 'lodash-es';
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {connect} from "react-redux";
import {apiTenant} from "../../Api/_ApiTenant";
import {isAdmin} from "../../utils/Utility";

class TenantMemberDetails extends Component {

    state = {
        tenantMember: {}
    };

    componentDidMount() {
        this.getTenantMemberDetail();
    }

    getTenantMemberDetail = () => {
        const { i18n } = this.props;
        let params = {
            $expand: 'tenant_member_role,tenant_member_stores/store'
        };
        apiTenant.getTenantMemberDetail(this.props.id, params).then(obj => {
            if(obj && obj.status === 200) {
                if(obj.data) {
                    const tenantMember = obj.data;
                    this.setBreadcrumbWithUserNumber({title: tenantMember.display_name, link: null })
                    this.setState( {
                        tenantMember: this.prepareData(tenantMember)
                    });
                }
            } else {
                this.props.history.push('/' + i18n.language + '/login');
            }
        })
    }

    setBreadcrumbWithUserNumber = (userInfoBreadCrumb) => {

        const { t, i18n } = this.props;
        const params= {
            '$select' : 'display_name',
            '$top' : 1
        };
        apiTenant.getTenantMemberList(params).then( (rs) => {
            if(rs && rs.status === 200) {
                const dataSum = rs.headers['content-range'].split('/')[1];

                this.props.setBreadcrumbP({breadcrumbData: [{title: t('UserManagement:breadcrumb') + ' (' + dataSum + ')',link: '/' + i18n.language + '/user-management'}, userInfoBreadCrumb]});

            } else {
                console.log("eCb : ", rs);
            }
        })
    }

    back = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/tenant-member-management?previous=true');
    }

    goToEditPage = () => {
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/tenant-member/'+this.state.tenantMember.tenant_member_id);
    }

    prepareData = data => {
        const item = {...data};
        item.update_date = item.lastmoddate ? moment(item.lastmoddate).format('YYYY-MM-DD') : '';
        return item;
    };

    render() {

        const { auth, t } = this.props;
        const { tenantMember } = this.state;
        const stores = map(get(tenantMember, 'tenant_member_stores'), property(['store', 'name']));

        return (
            tenantMember && (
                <div>
                    <div className="main__container flex-center-item">
                        <div className="full-width">
                            <Grid container xm={12} alignItems="center">
                                {isAdmin(auth.userInfo ? auth.userInfo.role : null) ?
                                    <Grid item xs={12} className="button-wrapper form-item pt20">
                                        <Button type="button" className="primary-button" onClick={() => { this.goToEditPage() }}>{t("UserManagement:button.edit")}</Button>
                                    </Grid>
                                : null}
                                <Grid item xs={2} className="form-item">
                                    {t("TenantManagement:displayName")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {tenantMember.display_name}
                                </Grid>

                                <Grid item xs={2} className="form-item">
                                    {t("UserManagement:role")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {get(tenantMember, ['tenant_member_role', 'name'], '')}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                    {t("TenantManagement:qrCode")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {tenantMember.qr_code}
                                </Grid>

                                <Grid item xs={2} className="form-item">
                                    {t("TenantManagement:scannedUser")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {get(tenantMember, 'user', '')}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                    {t("TenantManagement:scanned")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {tenantMember.activated}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                    {t("TenantManagement:active")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {tenantMember.active}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                    收集點
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {stores && stores.join(', ')}
                                </Grid>

                                <Grid item xs={2} className="form-item">
                                    {t("UserManagement:updatedDate")}
                                </Grid>
                                <Grid item xs={10} className="form-item">
                                    {tenantMember.update_date}
                                </Grid>
                                <Grid item xs={2} className="form-item">
                                </Grid>
                                <Grid item xs={12} className="button-wrapper form-item pt20">
                                    <Button type="button" className="primary-button" onClick={() => { this.back() }}>Back</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            )
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    breadcrumbArr: state.breadcrumb.breadcrumbArr
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(TenantMemberDetails)));