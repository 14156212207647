// Essential for all components
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';

// Styling
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

// Api
import { apiAuth } from '../../Api/ApiAuth';

// Redux
import { connect } from 'react-redux';
import { login, forgetPassword } from '../../Redux/Action/authAction';

// Utils
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Children components
import ErrorMessage from '../../components/100Include/ErrorMessage';
import GeneralMessage from '../../components/100Include/GeneralMessage';

class ForgetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            MessageContent: ''
        }
    }

    _forgetPasswordAsync = (values) => {
        if (typeof (values) !== 'undefined') {

            var promise = Promise.resolve();
            apiAuth.getClientCredentials().then((res) => {
                console.log("client credential",res);
                promise.then(() => {
                    this._forgetPassword(values, res.data.access_token);
                });
            })
        }
    }

    _forgetPassword = (values, token) => {
        const { t, 
            //i18n
         } = this.props;

        const cb = (obj) => {
            console.log("cb : ", obj);
            if(obj && obj.status!==404){
                this.props.forgetPasswordP(values.email);

                this.setState({
                    MessageContent: t('LoginRegister:forgetPassword.msgSent')
                });
    
                setTimeout(() => {
                    this.props.history.push('reset-password');
                }, 5000);
            }
            else{
                this.setState({MessageContent: t('LoginRegister:forgetPassword.emailNotExist')});
            }
        }

        const eCb = (obj) => {
            console.log("eCb : ", obj);
        }

        const params = null;
        const accept = 'application/json';
        const contentType = "application/x-www-form-urlencoded";
        apiAuth.forgetPassword(values.email, params, token, cb, eCb, accept, contentType);
    }

    formForgetPassword = ({ values, errors, touched, handleChange }) => {
        const { t, i18n } = this.props;

        return (
            <Grid item xm={12} md={6} xl={4} className="grid">
                <Form className="form-wrapper forgot-form">
                    <Grid item xs={12} className="grid title">
                        <h1 className="color-white">{t('Common:General.ForgetPassword')}</h1>
                        <h3 className="color-white">{t("LoginRegister:forgetPassword.description")}</h3>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} className="grid">
                            <Field name="email" type="text" placeholder={t("LoginRegister:placeholder.Email")} maxLength="100" style={{ 'width': '100%' }} />
                            {errors.email && touched.email ? <ErrorMessage message={errors.email} /> : null}
                        </Grid>
                        <Grid item xs={12} className="grid">
                            <Button type="submit">{t("LoginRegister:forgetPassword.send")}</Button>
                        </Grid>
                        <Grid item xs={12} className="grid align-center">
                            <Link to={"/" + i18n.language + '/login'}>{t("LoginRegister:forgetPassword.backTo")}</Link>
                        </Grid>
                        {(this.state.MessageContent !== '') &&
                        <Grid item xs={12} className="SuccessMessage">
                            <GeneralMessage
                                message={this.state.MessageContent}
                            />
                        </Grid>
                        }
                    </Grid>
                </Form>
            </Grid>
        )
    }

    render() {
        const { t } = this.props;

        // Redirect to home
        if (this.props.auth.auth) {
            return <Redirect push to={"/"} />
        }

        const Schema = Yup.object().shape({
            email: Yup.string()
                .email(t("LoginRegister:register.Checking1"))
                .required(t("LoginRegister:register.Checking2"))
        })

        return (
            <div className="forgot-page">
                <div className="main__container flex-center-item">
                    <Formik
                        initialValues={{
                            email: '',
                        }}
                        validationSchema={Schema}
                        onSubmit={this._forgetPasswordAsync}
                        component={this.formForgetPassword}
                    />

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    loginP: data => dispatch(login(data)),
    forgetPasswordP: data => dispatch(forgetPassword(data))
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgetPassword)));
