import { api, getBaseUrl } from "./_ApiFactoryWithHeader";

export const apiReport = {
    getReports: (platform, params) => {
        const url = `${getBaseUrl(platform)}/reports`;
        return api.get(url, params, null);
    },
    downloadReport: (platform, id, option) => {
        const url = `${getBaseUrl(platform)}/download_reports/${id}`;
        return api.getXlsxFile(url,{ responseType: 'blob', ...option });
    },

    getReportTypes: (params, cancelToken) => {
        const url = 'report_types';
        return api.get(url, params, null, cancelToken);
    },

    getReportDateRanges: (params, cancelToken) => {
        const url = 'report_date_ranges';
        return api.get(url, params, null, cancelToken);
    },

    generateReport: (body, params) => {
        let url = 'generate_report';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body, null, {
            responseType: 'blob'
        });
    },

    generateCheckoutReport: (body, params) => {
        let url = 'generate_checkout_report';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body, null, {
            responseType: 'blob'
        });
    },

    generateAdjustedReport: (body, params) => {
        let url = 'generate_adjusted_report';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body, null, {
            responseType: 'blob'
        });
    },

    generateCheckoutCombineReport: (body, params) => {
        let url = 'generate_checkout_combine_report';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body, null, {
            responseType: 'blob'
        });
    },

    generateAdjustedCombineReport: (body, params) => {
        let url = 'generate_adjusted_combine_report';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body, null, {
            responseType: 'blob'
        });
    },

    generateReportImage: (body, params) => {
        let url = 'generate_report_image';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body, null, {
            responseType: 'blob'
        });
    },

    generateReportReceiptImage: (body, params) => {
        let url = 'generate_report_receipt_image';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body, null, {
            responseType: 'blob'
        });
    },

    getOrderWeightSummaryList: (params, cancelToken) => {
        const url = 'order_weight_summaries';
        return api.get(url, params, null, cancelToken);
    },
    addOrderWeightSummary: (body, params) => {
        let url = 'order_weight_summaries';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body);
    },
    updateOrderWeightSummary: (id, body) => {
        const url = 'order_weight_summaries/' + id;
        return api.put(url, body);
    },

    getOrderWeightSummaryFileList: (params, cancelToken) => {
        const url = 'order_weight_summary_files';
        return api.get(url, params, null, cancelToken);
    },
    addOrderWeightSummaryFile: (body, params) => {
        let url = 'order_weight_summary_files';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body);
    },
    deleteOrderWeightSummaryFile: (id) => {
        const url = 'order_weight_summary_files/' + id;
        return api.delete(url);
    },

    getOrderItemList: (params, cancelToken) => {
        const url = 'order_items';
        return api.get(url, params, null, cancelToken);
    },
    updateOrderItem: (id, body) => {
        const url = 'order_items/' + id;
        return api.put(url, body);
    },

    getOrderWeightReceiptList: (params, cancelToken) => {
        const url = 'order_weight_receipts';
        return api.get(url, params, null, cancelToken);
    },
};
