// Essential for all components
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import Bluebird from 'bluebird';
import {get} from 'lodash-es';

import BaseListPage from "../../components/101BaseListPage/BaseListPage";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";

import moment from "moment";
import {
    getCurrentStateOfPage,
    getPreviousPage,
    getValAfterSlash,
    handleSpecialFieldName
} from "../../utils/PaginationUtils";
import queryString from 'query-string'
import {COMMON_DATE_FORMAT} from "../../constants/common";
import {addMessage} from "../../Redux/Action/messageAction";
import {apiTenant} from "../../Api/_ApiTenant";
import AlertDialog from '../01AuctionManagement/AlertDialog';

// Define column names
const rows = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Tenant Name',
        link: '/tenant-management/:tenant_id/view',
        customUrlParam: ['tenant_id']
    },
    {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: 'Type'
    },
    {
        id: 'lastmoddate',
        numeric: false,
        disablePadding: false,
        label: 'Update Date',
        type: 'date',
        dateFormat: 'YYYY-MM-DD HH:mm:ss'
    }
];

const rowsPerPageDefault = 10;
const orderByDefault = 'lastmoddate';
const orderDefault = 'desc';

class TenantManagement extends Component {

    state = {
        data: null,
        //pagination
        amountOfData: '',
        currentPage: 0,
        rowsPerPage: rowsPerPageDefault,
        orderBy: orderByDefault,
        order: orderDefault,
        alertDialogOpen: false,
        alertDialogCallback: null,
    };


    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        let previousPage = values.previous ? getPreviousPage(this.props.currentPaginator, 'tenant-management') : null;
        const {currentPage, rowsPerPage, orderBy, order} = getCurrentStateOfPage(previousPage, rowsPerPageDefault, orderByDefault, orderDefault)
        this.setState({
            currentPage: currentPage,
            rowsPerPage: rowsPerPage,
            orderBy: orderBy,
            order: order
        }, () => {
            this._getDataDynamic(currentPage * rowsPerPage, rowsPerPage, orderBy, order, true);
        });
    }

    _getDataDynamic = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        const {t, i18n} = this.props;
        orderBy = handleSpecialFieldName(orderBy);

        const params =
            {
                '$orderby': `${orderBy} ${order}`,
                '$top': rowsPerPage,
                '$skip': skipAmount,
                '$expand': 'tenant_type'
            };

        apiTenant.getTenantList(params).then((rs) => {
            if (rs && rs.status === 200) {
                if (isResetNavAndDataAmount) {
                    const dataSum = getValAfterSlash(rs.headers['content-range']);
                    this.setState({amountOfData: dataSum});
                    this.props.setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: t('TenantManagement:breadcrumb') + ' (' + dataSum + ')',
                                    link: '/' + i18n.language + '/tenant-management'
                                }
                            ]
                        }
                    );
                }
                this.setState({
                    data: this.prepareData(rs.data),
                });
            } else {
                console.log("eCb : ", rs);
            }
        });
    }

    prepareData = data => {
        return (data && data.length > 0) ? (
            data.map(item => ({
                ...item,
                id: item.tenant_id,
                update_date: moment(item.lastmoddate).format(COMMON_DATE_FORMAT),
                type: get(item, ['tenant_type', 'name']),
            }))
         ) : [];
    };

    _createButtonAction = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/tenant');
    }

    _updateButtonAction = (id) => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/tenant/' + id);
    }

    _deleteButtonAction = (ids, rowsPerPage) => {
        if (ids && ids.length > 0) {
            return Bluebird.map(ids, id => {
                return apiTenant.deleteTenant(id).then(res => {
                    if (res.status === 204) {
                        return;
                    } else {
                        const error = res.data && res.data.error ? res.data.error : 'Delete Tenant(s) failed';
                        throw new Error(error);
                    }
                })
            }, {
                concurrency: 4
            }).then(() => {
                const msgDsp = {
                    messageSnackbar: 'Delete Tenant(s) successfully',
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
                this._getDataDynamic(0, rowsPerPage, this.state.orderBy, this.state.order, true, null);
            }).catch(err => {
                const msgDsp = {
                    messageSnackbar: err.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
            });
        }
    }

    _loginAsTenantAdminAction = (ids, rowsPerPage, selectedData) => {
        if (!ids || ids.length != 1) {
            return;
        }

        const tenant = selectedData[0];
        localStorage.setItem('asTenantId', ids[0]);
        const tenantType = get(tenant, 'tenant_type');
        let tenantTypeId = get(tenantType, 'tenant_type_id');
        if (!tenantTypeId) {
            tenantTypeId = tenantType;
        }
        tenant.tenant_type = tenantTypeId;
        localStorage.setItem('asTenantTypeId', tenantTypeId);
        localStorage.setItem('asTenant', JSON.stringify(tenant));
        const { i18n } = this.props;
        this.props.history.push('/' + i18n.language + '/tenant-member-management');
    }

    render() {
        const { data, amountOfData, currentPage, rowsPerPage, orderBy, order, alertDialogOpen, alertDialogCallback } = this.state;
        return (
            data &&
            <div>
                <BaseListPage
                    pageId={'tenant-management'}
                    mainClass={'auction-mgt-page'}
                    primaryKey={'tenant_id'}
                    displaySearchBar={false}
                    displayActionBar={true}

                    createButton={true}
                    createButtonText={'New Tenant'}
                    createButtonAction={this._createButtonAction}

                    updateButton={true}
                    updateButtonText="Edit"
                    updateButtonAction={this._updateButtonAction}

                    deleteButton={true}
                    deleteButtonText="Delete"
                    deleteButtonAction={(ids, rowsPerPage) => this.setState({
                        alertDialogOpen: true,
                        alertDialogType: 'delete',
                        alertDialogCallback: () => this._deleteButtonAction(ids, rowsPerPage),
                    })}

                    remainButtons={[{
                        text: 'Login as Selected Tenant Admin',
                        selectedOnly: true,
                        className: 'stop-button',
                        onClick: (ids, rowsPerPage, selectedData) => {
                            this._loginAsTenantAdminAction(ids, rowsPerPage, selectedData);
                        },
                    }]}

                    getDataDynamic={this._getDataDynamic}
                    currentPage={currentPage}
                    rows={rows}
                    data={data}
                    displaySelectBox={true}
                    orderDefault={order}
                    orderByDefault={orderBy}
                    rowsPerPageDefault={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20]}
                    amountOfData={amountOfData}
                />
                <AlertDialog
                    isOpen={alertDialogOpen}
                    title="WARNING"
                    onAction={() => {
                        if (typeof alertDialogCallback === 'function') {
                            alertDialogCallback();
                        }
                        this.setState({ alertDialogOpen: false, alertDialogCallback: null });
                    }}
                    onCancel={() => this.setState({ alertDialogOpen: false, alertDialogCallback: null })}
                    actionButtonText="Delete"
                    cancelButtonText="No"
                    actionButtonClass="delete-button"
                >
                    Are you sure you want to Delete the Tenant(s)?<br />
                    You can't undo this.
                </AlertDialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    addMessageP: data => dispatch(addMessage(data)),
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(TenantManagement)));
