import * as PaginatorActionTypes from '../Constant/ActionType';

export const setPaginator = (currentPaginatorItem) => ({
    type: PaginatorActionTypes.SET_PAGINATOR,
    currentPaginatorItem,
});

export const getPaginator = (item) => ({
    type: PaginatorActionTypes.GET_PAGINATOR,
    item
});