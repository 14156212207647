import {api} from './_ApiFactoryWithHeader';

export const apiQrCode = {
    getList: (params, cancelToken) => {
        const url = 'qr_codes';
        return api.get(url, params, null, cancelToken);
    },
    getDetail: (id, params, cancelToken) => {
        const url = 'qr_codes/' + id;
        return api.get(url, params, null, cancelToken);
    },
    update: (id, body) => {
        const url = 'qr_codes/' + id;
        return api.put(url, body);
    },
    create: (body, params) => {
        let url = 'qr_codes';
        if (params) {
            const queryParams = new URLSearchParams(params);
            const queryParamsStr = queryParams.toString();
            if (queryParamsStr) {
                url += '?' + queryParamsStr
            }
        }
        return api.post(url, body);
    },
    delete: (id) => {
        const url = 'qr_codes/' + id;
        return api.delete(url);
    },

};
