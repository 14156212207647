import * as AuthActionTypes from '../Constant/ActionType';

// START FROM HERE
export const login = (data) => ({
	type: AuthActionTypes.LOGIN_SUCCESS,
	data
});

export const register = (data) => ({
	type: AuthActionTypes.REGISTER_SUCCESS,
	data
})

export const getUserInfo = (data) => ({
	type: AuthActionTypes.GET_USER_INFO,
	data
});

export const refreshTokenByRefreshToken = (data) => ({
	type: AuthActionTypes.REFRESH_TOKEN_BY_REFRESH_TOKEN,
	data
});

export const logout = () => ({
	type: AuthActionTypes.LOGOUT
});

export const forgetPassword = (data) => ({
	type: AuthActionTypes.FORGET_PASSWORD,
	data
});

export const forgetPasswordClear = () => ({
	type: AuthActionTypes.FORGET_PASSWORD_CLEAR
});
