import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {get} from 'lodash-es';

import {List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";

import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import GroupIcon from '@material-ui/icons/Group';
import StorefrontIcon from '@material-ui/icons/Storefront';
import CropFreeIcon from '@material-ui/icons/CropFree';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SettingsIcon from '@material-ui/icons/Settings';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import TuneIcon from '@material-ui/icons/Tune';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';

import {CacheService} from '../../utils/CacheService';

const menuItemMap = {
    collector: [
        {
            urls: ['store-management'],
            to: '/store-management',
            icon: <PlaceOutlinedIcon />,
            label: 'Sidebar:collection-point-management',
        },
        {
            urls: ['tenant-member-management'],
            to: '/tenant-member-management',
            icon: <GroupIcon />,
            label: 'Sidebar:user-list',
        },
        {
            urls: ['report'],
            to: '/report',
            icon: <DescriptionOutlinedIcon />,
            label: 'Sidebar:report',
        },
        {
            urls: ['configuration'],
            to: '/configuration',
            icon: <TuneIcon />,
            label: 'Sidebar:configuration',
        }
    ],
    processor: [
        {
            urls: ['factory-management'],
            to: '/factory-management',
            icon: <PlaceOutlinedIcon />,
            label: 'Sidebar:factory-management',
        },
        {
            urls: ['tenant-member-management'],
            to: '/tenant-member-management',
            icon: <GroupIcon />,
            label: 'Sidebar:user-list',
        },
        {
            urls: ['report'],
            to: '/report',
            icon: <DescriptionOutlinedIcon />,
            label: 'Sidebar:record-and-report',
        },
        {
            urls: ['configuration'],
            to: '/configuration',
            icon: <TuneIcon />,
            label: 'Sidebar:configuration',
        }
    ],
    system: [
        {
            urls: ['tenant-management'],
            to: '/tenant-management',
            icon: <StoreMallDirectoryIcon />,
            label: 'Sidebar:tenant-management',
        },
        {
            urls: ['user-management'],
            to: '/user-management',
            icon: <GroupIcon />,
            label: 'Sidebar:user-list',
        },
        // {
        //     urls: ['qr-code-management'],
        //     to: '/qr-code-management',
        //     icon: <GroupIcon />,
        //     label: 'Sidebar:qr-code-management',
        // },
        {
            urls: ['report'],
            to: '/report',
            icon: <DescriptionOutlinedIcon />,
            label: 'Sidebar:report',
        },
        {
            urls: ['configuration'],
            to: '/configuration',
            icon: <TuneIcon />,
            label: 'Sidebar:configuration',
        }
    ]
};

/*
const menuItemsCMS = [
    {
        urls: ['user-management'],
        to: '/user-management',
        icon: <PersonIcon />,
        label: 'Sidebar:user-list',
    },
    {
        urls: ['user-group-management'],
        to: '/user-group-management',
        icon: <PeopleIcon />,
        label: 'Sidebar:user-group',
    },
    {
        urls: ['factory-management'],
        to: '/factory-management',
        icon: <StorefrontIcon />,
        label: 'Sidebar:factory',
    },
    {
        urls: ['qr-code-group-management'],
        to: '/qr-code-group-management',
        icon: <CropFreeIcon />,
        label: 'Sidebar:qr-code-group',
    },
    {
        urls: ['order-management'],
        to: '/order-management',
        icon: <LocalShippingIcon />,
        label: 'Sidebar:order',
    },
    {
        urls: ['machine-management'],
        to: '/machine-management',
        icon: <SettingsIcon />,
        label: 'Sidebar:machine',
    },
    {
        urls: ['box-management'],
        to: '/box-management',
        icon: <CardGiftcardIcon />,
        label: 'Sidebar:box',
    },
    {
        urls: ['report'],
        to: '/report',
        icon: <BarChartIcon />,
        label: 'Sidebar:report',
    },
];
*/

class Sidebar extends Component {

    render() {
        const { t, i18n, auth, currentURL } = this.props;
        const profileData = CacheService.getProfileData();
        const roleInString = get(profileData, 'role_in_string', '');
        const roles = roleInString.split(',');
        let role = roles[0];
        if (role) {
            role = role.trim();
        }
        let cmsRole;
        switch (role) {
            case 'admin':
                cmsRole = 'system';
                break;
            case 'store_owner':
            case 'driver':
                cmsRole = 'collector';
                break;
            case 'factory_manager':
            case 'check_in_staff':
            case 'check_out_staff':
            case 'processing_staff':
                cmsRole = 'processor';
                break;
        }
        const tenantTypeId = localStorage.getItem('asTenantTypeId');
        if (tenantTypeId) {
            switch (tenantTypeId) {
                case 'collector':
                    cmsRole = 'collector';
                    break;
                case 'processor':
                    cmsRole = 'processor';
                    break;
            }
        }
        const menuItemsCMS = menuItemMap[cmsRole];
        return (
            <div className="sidebar__container">
                <div className="sidebar__container_logo">
                    <img src={process.env.PUBLIC_URL + "/images/Logo_small.png"} />
                </div>
                {auth.auth &&
                    <div className="sidebar-menu">
                        <List>
                            {menuItemsCMS && menuItemsCMS.map((menuItem, i) => {
                                const { urls, to, icon, label } = menuItem;
                                return (
                                    <ListItem
                                        key={i}
                                        className={urls.includes(currentURL) ? 'active' : ''}
                                        component={Link}
                                        to={`/${i18n.language}${to}`}
                                    >
                                        <ListItemIcon className="menu-item-icon" >{icon}</ListItemIcon>
                                        <ListItemText primary={t(label)} />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar)));