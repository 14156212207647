// Essential for all components
import React, {Component, Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
// Api
import {logout} from "../../Redux/Action/authAction";

import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from "@material-ui/core/AppBar";


const styles = {
    appBar: {
        width: `100%`,
        backgroundImage: 'white',
        alignItems: 'center',
    },
    typography: {
        flexGrow: 1,
        align: "center"
    }
};

class PublicHeader extends Component {
    render() {
        return (
            <Fragment></Fragment>
        )
    }
}

PublicHeader.propTypes = {
    siteType: PropTypes.object,
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    router: state.router,
    auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
    logoutP: data => dispatch(logout(data)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(PublicHeader))));